import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import PlayerEngagementControls from './PlayerEngagementControls';
import './PlayerEngagementPage.scss';
import Player from '../../components/player/Player';
import { useStore } from '../../utils/store';

const videoList = [
  'gautodemo/eltmkteifxtcmmfv0vzy',
  'gautodemo/sqeycfljvsx7ckfo0qdi',
  'red_dress',
  'gautodemo/udzqadckuhnxnua3ii8q'
];

const playerEngagementControls = [
  {
    key: 1,
    type: 'toggleSwitch',
    name: 'alwayOnPlaylist',
    label: 'playerEngagement.controls.alwayOnPlaylist',
    storeKey: 'alwaysOnPlaylist'
  },
  {
    key: 2,
    type: 'toggleSwitch',
    name: 'endScreenPlaylist',
    label: 'playerEngagement.controls.endScreenPlaylist',
    storeKey: 'endScreenPlaylist'
  }
];
const pageStrings = {
  title: 'playerEngagement.title',
  subtitle: 'playerEngagement.subtitle',
  assetSelectTitle: 'playerEngagement.assetSelectTitle',
  controlsTitle: 'playerEngagement.controlsTitle'
};

function PlayerEngagementPage() {
  const { t } = useTranslation();

  const { store, updateStore, resetStore } = useStore();
  const { selectedAssets, alwaysOnPlaylist, endScreenPlaylist } = store;
  const curAssets = selectedAssets?.length > 0 ? selectedAssets : videoList;
  useEffect(() => {
    updateStore({ selectedAssets: videoList });
  }, []);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  return (
    <div className="page-container player-engagement-page">
      <PageTopSection
        assetsSelectType="multiPhoto"
        isVideo={true}
        assets={videoList}
        strings={pageStrings}
        controls={
          <PlayerEngagementControls
            controls={playerEngagementControls}
            title={t(pageStrings.controlsTitle)}
          />
        }
      />
      <div className="bottom-section">
        {
          <Player
            alwaysOnPlaylist={alwaysOnPlaylist}
            endScreenPlaylist={endScreenPlaylist}
            publicIds={curAssets}
            config={{}}
          ></Player>
        }
      </div>
    </div>
  );
}

export default PlayerEngagementPage;
