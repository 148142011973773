import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import './SelectInput.scss';
import { useStore } from '../../utils/store';

function SelectInput(props) {
  const { control, onChange, handleChange, handledValue } = props;

  const { store, updateStore } = useStore();

  const selectedOption = handledValue ?? store[control.storeKey];
  const handle = event => {
    if (handleChange) {
      handleChange(event.target.value);
      return;
    }
    updateStore({ [control.storeKey]: event.target.value });
    if (onChange) {
      onChange(control, event.target.value);
    }
  };

  return (
    <div className="select-container">
      <Select
        value={selectedOption}
        onChange={handle}
        sx={{
          height: 24,
          fontSize: 14,
          fontFamily: 'inter'
        }}
      >
        {control.selectOptions.map(option => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
}

export default SelectInput;
