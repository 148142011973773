import React from 'react';
import { useTranslation } from 'react-i18next';
import './ToggleSwitch.scss';
import { useStore } from '../../utils/store';
const getValue = (control, store) => {
  if (control.group) {
    return store[control.group][control.storeKey].enabled ?? false;
  }
  return store[control.storeKey] ?? false;
};

function ToggleSwitch(props) {
  const { control, onChange, handleChange, handledValue } = props;
  const { t } = useTranslation();
  const { store, updateStore } = useStore();
  const value = handledValue !== undefined ? handledValue : getValue(control, store);
  const handleToggle = () => {
    if (handleChange) {
      handleChange(control.storeKey, !value);
      return;
    }
    updateStore({ [control.storeKey]: !value });
    if (control.group) {
      const newVal = {
        ...store[control.group][control.storeKey],
        enabled: !value
      };
      updateStore({
        [control.group]: { ...store[control.group], [control.storeKey]: newVal }
      });
    } else {
      updateStore({ [control.storeKey]: !value });
    }

    if (onChange) {
      onChange(control, !value);
    }
  };

  return (
    <div className="toggle-switch-container">
      <label className="switch">
        <input type="checkbox" checked={value} onChange={handleToggle} />
        <span className="slider round"></span>
      </label>
      <div className="label">
        <div>{t(control.label)}</div>
      </div>
    </div>
  );
}

export default ToggleSwitch;
