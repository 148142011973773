export const getVideoSize = async url => {
  try {
    const result = await fetch(url, { method: 'HEAD' });
    const size = result.headers.get('content-length');
    const type = result.headers.get('content-type').split('/')[1];
    return { size, type };
  } catch (error) {
    console.error('error', error);
  }

  return { size: 0, type: '' };
};

export const calculatePercentage = (origSize, newSize) => {
  const percentage = Math.round((newSize / origSize) * 100);
  const saving = 100 - percentage;
  return { percentage, saving };
};
