import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';
import Tooltip from '@mui/material/Tooltip';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Spinner from 'components/loaderComponent/Spinner';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '16px',
          padding: '10px 20px 10px 10px'
        }
      }
    }
  }
});

function SmartIntegrationsControls(props) {
  const { t } = useTranslation();
  const { controls, data, isLoadingTags, isLoadingTranscript } = props;
  const checkIsLoaded = control => {
    if (control.name === 'transcript') {
      return !isLoadingTranscript;
    }
    if (control.name === 'tags') {
      return !isLoadingTags;
    }
    return false;
  };
  const getControlData = control => {
    return data[control.name];
  };

  const getTooltipContent = item => {
    return (
      <div className="tooltip-content">
        <div style={{ fontWeight: '700', marginBottom: '6px' }}>{t('appearsIn')}:</div>
        {item.appearsIn.map((entry, index) => {
          if (index < 3) {
            return <div>{`${entry.startTimeOffset} - ${entry.endTimeOffset}`}</div>;
          }
          return null;
        })}
        {item.appearsIn.length > 3 && <div>{`+${item.appearsIn.length - 3} ${t('more')}`}</div>}
      </div>
    );
  };

  return (
    <div className="controls-section">
      <div className="controls">
        {controls.map(control => (
          <div key={control.key} className="control-container">
            {checkIsLoaded(control) ? (
              <>
                <div className="header">
                  <div className="control-label">{t(control.label)}</div>
                  {control.toggleSwitch && (
                    <ToggleSwitch key={control.toggleSwitch.key} control={control.toggleSwitch} />
                  )}
                </div>
                <div className={clsx(['items', [control.name]])}>
                  <ThemeProvider theme={theme}>
                    {getControlData(control).map(item =>
                      control.name === 'tags' ? (
                        <Tooltip title={getTooltipContent(item)}>
                          <div key={item.key} className="item">
                            {control.name === 'tags' && <div className="text">{item.tag}</div>}
                          </div>
                        </Tooltip>
                      ) : (
                        <div key={item.key} className="item">
                          {control.name === 'transcript' && <div className="time">{item.word}</div>}
                        </div>
                      )
                    )}
                  </ThemeProvider>
                </div>
              </>
            ) : (
              <div className="spinner-container">
                <Spinner size={20} thickness={3} />
                <div className="text">{`Generating ${control.name}`}</div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default SmartIntegrationsControls;
