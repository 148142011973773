import { AdvancedImage } from '@cloudinary/react';
import { cld } from '../../utils/cld';
import { fit } from '@cloudinary/url-gen/actions/resize';
import Image from 'components/image/Image';

const VideoThumbnail = props => {
  const { asset } = props;

  if (!asset) {
    return null;
  }
  return (
    <AdvancedImage
      cldImg={cld.image(asset).setAssetType('video').delivery('q_auto').format('auto:image').resize(fit().width(200))}
    />
  );
};

export default VideoThumbnail;
