import { cld } from '../../utils/cld';
import { crop } from '@cloudinary/url-gen/actions/resize';

export const getConfig = (hotspots, hotSpotContainerDimensions, curAsset, sources) => {
  const template = Object.keys(hotspots)
    .filter(key => hotspots[key].enabled)
    .map(key => {
      const xInPercentage = (hotspots[key].x / hotSpotContainerDimensions.width) * 100;
      const yInPercentage = (hotspots[key].y / hotSpotContainerDimensions.height) * 100;
      let newUrl = cld
        .video(curAsset)
        .resize(
          crop()
            .width(0.3)
            .height(0.3)
            .x((xInPercentage / 100 - 0.15).toFixed(1))
            .y((yInPercentage / 100 - 0.15).toFixed(1))
        )
        .toURL();
      newUrl += '.webm';
      sources[key] = newUrl;

      return {
        left: xInPercentage,
        top: yInPercentage,
        width: 5,
        height: 5,
        id: key
      };
    });
  const data = {
    interactionAreas: {
      enable: true,
      template,
      onClick: event => {
        const handler = hotspots[event.item.id].effect;
        if (handler === 'url') {
          const url = hotspots[event.item.id].url;
          if (url !== '') {
            window.open(url, '_blank');
          }
        }
        if (handler === 'zoomin') {
          event.zoom(sources[event.item.id]);
        }
      }
    }
  };
  return data;
};
