import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { cld } from '../../utils/cld';
import Video from '../../components/video/Video';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import VideoTransformationsControls from './VideoTransformationsControls';
import './VideoTransformationsPage.scss';
import { useStore } from '../../utils/store';
import RoundCornersAction from '@cloudinary/transformation-builder-sdk/actions/roundCorners/RoundCornersAction';
import { accelerate, blur, vignette } from '@cloudinary/url-gen/actions/effect';
import { saturation } from '@cloudinary/url-gen/actions/adjust';
import { byAngle } from '@cloudinary/url-gen/actions/rotate';

const videoList = [
  'samples/carwash_sy0spv',
  'samples/city_transform_um0497',
  'samples/lake_transform_khnlpz',
  'samples/podcast_slxy9c'
];

const videoInteractivityControls = [
  {
    key: 1,
    type: 'slider',
    valueType: 'percentage',
    name: 'vignette',
    label: 'videoTransformations.controls.vignette',
    group: 'videoTransformations',
    storeKey: 'vignette'
  },
  {
    key: 2,
    type: 'slider',
    maxVal: 300,
    valueType: 'pixels',
    name: 'roundCorners',
    label: 'videoTransformations.controls.roundCorners',
    group: 'videoTransformations',
    storeKey: 'roundCorners'
  },
  {
    key: 3,
    type: 'slider',
    valueType: 'percentage',
    name: 'saturation',
    label: 'videoTransformations.controls.saturation',
    group: 'videoTransformations',
    storeKey: 'saturation'
  },

  {
    key: 5,
    type: 'slider',
    valueType: 'percentage',
    name: 'overlay',
    label: 'videoTransformations.controls.overlay',
    group: 'videoTransformations',
    storeKey: 'overlay'
  },
  {
    key: 6,
    type: 'rotation',
    name: 'rotate',
    label: 'videoTransformations.controls.rotate',
    group: 'videoTransformations',
    storeKey: 'rotate'
  },

  {
    key: 8,
    type: 'slider',
    step: 0.5,
    minVal: 1,
    maxVal: 2,
    valueType: 'time',
    name: 'playbackSpeed',
    label: 'videoTransformations.controls.playbackSpeed',
    group: 'videoTransformations',
    storeKey: 'playbackSpeed'
  },
  {
    key: 9,
    type: 'slider',
    valueType: 'percentage',
    name: 'blur',
    label: 'videoTransformations.controls.blur',
    group: 'videoTransformations',
    storeKey: 'blur'
  }
];

const pageStrings = {
  title: 'videoTransformations.title',
  subtitle: 'videoTransformations.subtitle',
  detailed: 'videoTransformations.detailed',
  assetSelectTitle: 'videoTransformations.assetSelectTitle',
  controlsTitle: 'videoTransformations.controlsTitle'
};
const getAccelerateRate = playbackSpeed => {
  if (playbackSpeed === 1) {
    return 0;
  } else if (playbackSpeed === 1.5) {
    return 50;
  } else {
    return 100;
  }
};

function VideoTransformationsPage() {
  const { t } = useTranslation();
  const { store, updateStore, resetStore } = useStore();
  const { videoTransformations, selectedAsset } = store;
  const currentVideo = selectedAsset ? cld.video(selectedAsset) : cld.video(videoList[0]);
  const [myVideo, setMyVideo] = useState(currentVideo);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  useEffect(() => {
    updateStore({ selectedAsset: videoList[0] });
  }, []);
  useEffect(() => {
    if (selectedAsset) {
      updateVideoTransformations();
    }
  }, [selectedAsset]);
  const updateVideoTransformations = () => {
    let updatedVideo = cld.video(selectedAsset);
    updatedVideo.resize('h_540');
    const {
      vignette: viggneteVal,
      roundCorners,
      saturation: saturationVal,
      playbackSpeed,
      blur: blurVal
    } = videoTransformations;
    const activeTransformations = Object.keys(videoTransformations).filter(
      key => videoTransformations[key].enabled
    );

    for (const transformation of activeTransformations) {
      switch (transformation) {
        case 'roundCorners':
          const roundCornersAction = new RoundCornersAction();
          roundCornersAction.radius(roundCorners.value);
          updatedVideo.roundCorners(roundCornersAction);
          break;
        case 'playbackSpeed':
          updatedVideo.effect(accelerate().rate(getAccelerateRate(playbackSpeed.value)));
          break;
        case 'vignette':
          updatedVideo.effect(vignette().strength(viggneteVal.value));
          break;
        case 'saturation':
          updatedVideo.adjust(saturation().level(saturationVal.value));
          break;
        case 'rotate':
          updatedVideo.rotate(byAngle(180));
          break;
        case 'blur':
          updatedVideo.effect(blur().strength(blurVal.value));
          break;
        default:
          break;
        // case 'overlay':
        //   updatedVideo.overlay(source(image('cloudinary_icon_blue')).blendMode('overlay'));
      }
    }
    setMyVideo(updatedVideo);
  };

  const bgimg = <div class="bg-img"></div>;
  const tag = <div class="tag"><a href="https://cloudinary.com/demos?filter=videos">DEMOS</a> / Video Transformations</div>;
  const title = <h1>Video Transformations</h1>;
  const desc = <p>Enhance, edit or personalize videos at scale.</p>;
  const extra = <p class="extra">Results are generated in real time (can take up to few minutes).</p>
  const docs = <div class="docs-parent">
  <p class="orange">Original</p>
  <p>https://res.cloudinary.com/demo/video/upload/rafting</p>
  <p class="orange">Transformed</p>
  <p>https://res.cloudinary.com/demo/video/upload/e_preview:duration_5/rafting.webm</p>
  </div>
  const links = <div className="links">Links: <a target="_blank" href="https://cloudinary.com/blog/8-video-transformations-developers-can-make-with-one-line-of-code">Blog</a> | <a href="https://cloudinary.com/documentation/video_manipulation_and_delivery" target="_blank">Docs</a> </div>;


  const otherTitle = <h2 class="other-title">Other Video Demos</h2>;
  const other = 
    <div class="row grid">      
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-optimization">
          <article>
            <h3>Dynamic Video Transcoding</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.mp4" width="100%" loop="" autoplay="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/adaptive-bitrate">
          <article>
            <h3>Adaptive Bitrate Streaming</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://studio.cloudinary.com/">
          <article>
            <h3>Video Player Studio</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-previews">
          <article>
            <h3>Automatic Video Previews</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-smart-cropping">
          <article>
            <h3>Video Smart Cropping</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-transformations">
          <article>
            <h3>Video Transformations</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712358821/Video-Transformation-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712358821/Video-Transformation-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/image-to-video">
          <article>
            <h3>Image to Video</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712512097/zoom-pan-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712512097/zoom-pan-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-generation">
          <article>
            <h3>Video Generation</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712769476/videoAPI_video-gen-thumb-fashion" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712769476/videoAPI_video-gen-thumb-fashion.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://demo.cloudinary.com/uw">
          <article>
            <h3>Upload Widget</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
    </div>;

  return (
    <div className="page-container video-transformations-page">

      <div className="hero">
      { tag }
      { title }
      { desc }
      </div>

      <PageTopSection
        assetsSelectType="video"
        assets={videoList}
        strings={pageStrings}
        controls={false}
      />
      <div className="bottom-section">
      <VideoTransformationsControls
        updateVideoTransformations={updateVideoTransformations}
        controls={videoInteractivityControls}
        title={t(pageStrings.controlsTitle)}
      />
      <Video cldVid={myVideo}></Video>
      </div>
      {links}
      {otherTitle}
      {other}
    </div>
  );
}

export default VideoTransformationsPage;
