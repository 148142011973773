import React from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import SelectInput from 'components/selectInput/SelectInput';
import IconButton from '@mui/material/IconButton';
import ChromeLogo from 'assets/svg-icons/ChromeLogo';
import ExplorerLogo from 'assets/svg-icons/ExplorerLogo';
import FirefoxLogo from 'assets/svg-icons/FirefoxLogo';
import OperaLogo from 'assets/svg-icons/OperaLogo';
import SafariLogo from 'assets/svg-icons/SafariLogo';
import DownloadIcon from 'assets/svg-icons/DownloadIcon';
import OpenIcon from 'assets/svg-icons/OpenIcon';
import { filesize } from 'filesize';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 18,
  borderRadius: 9,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#D9D9D9'
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 9,
    backgroundColor: '#5AE468'
  }
}));

const browsers = {
  chrome: <ChromeLogo />,
  explorer: <ExplorerLogo />,
  firefox: <FirefoxLogo />,
  opera: <OperaLogo />,
  safari: <SafariLogo />
};

function VideoOptimizationControls(props) {
  const { title, subtitle, controls, values, urls } = props;
  const { t } = useTranslation();

  return (
    <div className="controls-section">
      <p className="title">{title}</p>
      <div className="controls">
        {controls.formats.map(control => (
          <div key={control.key} className="control">
            <div className="control-header">{control.name}</div>
            <div className="control-info">
              <div className="format">{`${control.info.format} ${control.info.resolution}`}</div>
              <div className="browsers">
                {control.info.browsers?.map(browser => (
                  <div key={browser} className="browser-logo">
                    {browsers[browser]}
                  </div>
                ))}
              </div>
              <div className="size">{filesize(values[control.key].size)}</div>
              <IconButton size="small" className="download-button">
                <DownloadIcon />
              </IconButton>

              <IconButton size="small" className="open-button" data-value={urls[control.key+'Url']} >
                <a href={urls[control.key+'Url']} target="_blank">
                  <OpenIcon />
                </a>
              </IconButton>            
            </div>

            <div className="control-bar">
              <BorderLinearProgress variant="determinate" value={(() => {const saving = values[control.key].saving; const result = 100 - saving; return result > 100 ? 100 : result;})()} />
              {!values[control.key].loaded && (
                <div className="calculating">
                  {t('calculating')}
                  <div className="snippet" data-title="dot-pulse">
                    <div className="stage">
                      <div className="dot-pulse"></div>
                    </div>
                  </div>
                </div>
              )}
              {values[control.key].loaded && !values[control.key].isOriginal && (
                <div className="bar-reduced-text">{`${
                  values[control.key].saving > 0 ? t('reducedBy') : t('increasedBy')
                }: ${Math.abs(values[control.key].saving)}%`}</div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className="profile">
        <p className="profile-title">{subtitle}</p>
        <SelectInput key={controls.selectControl.storeKey} control={controls.selectControl} />
      </div>
    </div>
  );
}

export default VideoOptimizationControls;
