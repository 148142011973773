import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SvgIconChildren() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 12">
        <path
          d="M2.82052 3.06693C1.50277 3.88712 0.749512 5.05255 0.749512 5.05255C0.966444 2.33629 3.09515 0.132812 5.95865 0.132812C8.32921 0.132812 10.2701 1.72288 10.7732 3.78096C11.0155 4.77377 10.9753 5.73381 10.9753 6.56185H4.0178C4.0178 6.56185 3.84795 8.89917 7.04746 8.89917C8.14505 8.89917 9.18817 8.65178 10.042 8.06837L10.043 10.4039C8.82123 11.1567 6.5199 11.4585 5.04523 10.9799C3.32593 10.4219 2.03772 8.94071 2.03772 6.8037C2.03772 5.79381 2.38296 4.82962 2.9816 4.13267C3.75702 3.22986 4.73737 2.83246 4.73737 2.83246C4.31735 3.32125 4.04226 3.98036 3.97118 4.59746H7.8995C7.8995 4.59746 8.12935 2.24998 5.67664 2.24998C4.7526 2.24998 3.61763 2.57076 2.82052 3.06693Z"
          fill="#777777"
        />
      </svg>
    </SvgIcon>
  );
}
