import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudinaryLogo from 'assets/svg-icons/CloudinaryLogo';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.scss';
import { useStore } from '../../utils/store';

const pages = [
  { name: 'aiZoompan', route: '/smart-zoompan' },
  { name: 'imagesToVideo', route: '/images-to-video' },
  { name: 'automatedVideo', route: '/automated-video' },
  { name: 'aiCropping', route: '/ai-cropping' },
  { name: 'smartIntegrations', route: '/smart-integrations' },
  { name: 'playerFlexibility', route: 'https://studio.cloudinary.com', external: true },
  { name: 'videoTransformations', route: '/video-transformations' },
  { name: 'playerEngagement', route: '/player-engegament' },
  { name: 'videoInteractivity', route: '/video-interactivity' },
  { name: 'videoOptimizations', route: '/video-optimization' }
];

function Header(){

    const demos = process.env.demos;
    const activeLink = window.location.pathname;
    let isActive;




  useEffect(() => {
    // Toggle Mobile Menu
    const handleNavbarToggle = (e) => {
      e.preventDefault();
      mobileMenu();
    };

    const handleDropdownItemClick = () => {
      mobileMenu();
    };

    const handleMenuClick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (e.currentTarget.classList.contains('menu-item-has-children')) {
        e.currentTarget.classList.toggle('show-sub');
      }
    };

    const mobileMenu = () => {
      document.body.classList.toggle('no-overflow');
      const navbarHeight = document.querySelector('.navbar-header').offsetHeight;
      const menuHeight = navbarHeight;
      document.getElementById('site-main-nav').style.maxHeight = `calc(100vh - ${menuHeight}px)`;
      document.getElementById('navbar-toggler').classList.toggle('open');
      document.getElementById('site-main-nav').classList.toggle('collapse');
      
      // document.querySelector('.navbar-toggler').classList.toggle('open');
      document.getElementById('nav-icon').classList.toggle('open');
    };

    document.querySelector('.navbar-toggle').addEventListener('click', handleNavbarToggle);

    document.querySelectorAll('#top-right-menu .menu-item-has-children > a').forEach(item => {
      item.addEventListener('click', handleMenuClick);
    });

    if (window.innerWidth < 992) {
      document.querySelectorAll('.dropdown-toggle').forEach(item => {
        item.setAttribute('data-toggle', 'dropdown');
      });
    }

    const initAddClass = () => {
      const win = window;
      const fixedClass = 'fixed-position';
      const stickyTop = document.querySelector('.header-frame').offsetTop;
      const stickyHeight = document.querySelector('.header-frame').offsetHeight;
      const header = document.getElementById('header');
      const fixedSpacer = document.getElementById('fixed-spacer');

      const onScroll = () => {
        if (win.pageYOffset >= stickyTop) {
          header.classList.add(fixedClass);
          fixedSpacer.style.height = `${stickyHeight}px`;
        } else {
          header.classList.remove(fixedClass);
          fixedSpacer.style.height = '0';
        }
      };

      onScroll();
      win.addEventListener('scroll', onScroll);
      win.addEventListener('resize', onScroll);
      win.addEventListener('orientationchange', onScroll);
    };

    initAddClass();

    return () => {
      document.querySelector('.navbar-toggle').removeEventListener('click', handleNavbarToggle);
      
      document.querySelectorAll('#top-right-menu .menu-item-has-children > a').forEach(item => {
        item.removeEventListener('click', handleMenuClick);
      });
      window.removeEventListener('scroll', initAddClass);
      window.removeEventListener('resize', initAddClass);
      window.removeEventListener('orientationchange', initAddClass);
    };
  }, []);








      return (
        <>
<link href="https://cdn.jsdelivr.net/npm/typeface-roboto-mono@1.1.13/index.min.css" rel="stylesheet"/>
<section id={"headerBleed"}>
<header id="header">
  <div id="header-inner">
    <div className="header-frame">
      <nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-header">
            <div className="logo">
              <a href="https://cloudinary.com">
                <svg
                  id="cld-main-logo"
                  style={{ height: 30, width: 160, marginBottom: "-10px" }}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 500 96.77"
                >
                  <title>Cloudinary Logo</title>
                  <defs>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: ".cls-1{fill:#3448c5;}"
                      }}
                    />
                  </defs>
                  <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                      <path
                        className="cls-1"
                        d="M160.53,30.41a17.14,17.14,0,0,1,13.56,6.7.69.69,0,0,0,1,.11l5.71-4.55a.71.71,0,0,0,.11-1,26,26,0,0,0-20.61-10.13c-14.91,0-27,12.85-27,28.65s12.13,28.65,27,28.65A25.85,25.85,0,0,0,180.9,68.72a.69.69,0,0,0-.12-1l-5.7-4.5a.71.71,0,0,0-1,.11A17.26,17.26,0,0,1,160.53,70c-10.19,0-18.16-8.7-18.16-19.79S150.34,30.41,160.53,30.41Z"
                      />
                      <path
                        className="cls-1"
                        d="M188.27,19.91h7.16a.71.71,0,0,1,.71.71V77.4a.7.7,0,0,1-.7.7h-7.16a.71.71,0,0,1-.71-.71V20.62A.7.7,0,0,1,188.27,19.91Z"
                      />
                      <path
                        className="cls-1"
                        d="M220.54,39.55c-9.49,0-19.09,6.72-19.09,19.57,0,11.29,8.21,19.81,19.09,19.81s19.17-8.52,19.17-19.81S231.47,39.55,220.54,39.55Zm10.53,19.57c0,6.52-4.53,11.44-10.53,11.44S210.1,65.64,210.1,59.12s4.49-11.2,10.44-11.2S231.07,52.73,231.07,59.12Z"
                      />
                      <path
                        className="cls-1"
                        d="M278.3,40.37h-7.16a.7.7,0,0,0-.71.7v19c0,7.42-5.12,10.05-9.51,10.05-3.88,0-7.79-2.93-7.79-9.48V41.07a.7.7,0,0,0-.71-.7h-7.16a.7.7,0,0,0-.7.7v20.5c0,11.25,5.09,17.44,14.34,17.44,3.36,0,8.8-1.93,10.84-6.19l.69.14V77.4a.71.71,0,0,0,.71.71h7.16a.71.71,0,0,0,.71-.71V41.07A.7.7,0,0,0,278.3,40.37Z"
                      />
                      <path
                        className="cls-1"
                        d="M322.27,19.91H315.1a.7.7,0,0,0-.7.71V46l-.44-.7c-2.18-3.51-6.87-5.78-11.95-5.78-8.76,0-17.62,6.75-17.62,19.65,0,11.25,7.61,19.73,17.69,19.73,3.84,0,9.25-1.54,11.88-5.86l.44-.72V77.4a.7.7,0,0,0,.7.71h7.17a.7.7,0,0,0,.7-.71V20.62A.7.7,0,0,0,322.27,19.91Zm-8,39.21a11,11,0,0,1-10.75,11.36c-5.86,0-10.45-5-10.45-11.36s4.59-11.2,10.45-11.2A11,11,0,0,1,314.24,59.12Z"
                      />
                      <path
                        className="cls-1"
                        d="M333,40.37h7.16a.7.7,0,0,1,.7.7V77.4a.7.7,0,0,1-.7.7H333a.71.71,0,0,1-.71-.71V41.07A.71.71,0,0,1,333,40.37Z"
                      />
                      <path
                        className="cls-1"
                        d="M336.61,21.06a5.57,5.57,0,0,0-5.69,5.57,5.64,5.64,0,0,0,5.69,5.58,5.54,5.54,0,0,0,5.61-5.58A5.48,5.48,0,0,0,336.61,21.06Z"
                      />
                      <path
                        className="cls-1"
                        d="M370.35,39.55c-3.14,0-8.72,1.69-10.85,6.19l-.69-.14V41.07a.7.7,0,0,0-.71-.7h-7.16a.7.7,0,0,0-.7.7V77.4a.7.7,0,0,0,.7.71h7.16a.71.71,0,0,0,.71-.71v-19c0-7.36,5.12-10,9.51-10,3.88,0,7.79,2.91,7.79,9.4V77.4a.71.71,0,0,0,.71.71H384a.71.71,0,0,0,.71-.71V56.91C384.69,45.72,379.59,39.55,370.35,39.55Z"
                      />
                      <path
                        className="cls-1"
                        d="M427.48,40.37h-7.16a.7.7,0,0,0-.71.7v5l-.43-.7c-2.19-3.51-6.88-5.78-12-5.78-8.75,0-17.62,6.75-17.62,19.65,0,11.25,7.61,19.73,17.7,19.73,3.83,0,9.24-1.54,11.88-5.86l.43-.72V77.4a.71.71,0,0,0,.71.71h7.16a.7.7,0,0,0,.7-.71V41.07A.7.7,0,0,0,427.48,40.37Zm-8,18.75A11,11,0,0,1,408.7,70.48c-5.86,0-10.44-5-10.44-11.36s4.58-11.2,10.44-11.2A11,11,0,0,1,419.46,59.12Z"
                      />
                      <path
                        className="cls-1"
                        d="M460.15,40.5a13.66,13.66,0,0,0-5.14-1c-4.76,0-8.22,2.85-10,8.25l-.64-.09V41.07a.7.7,0,0,0-.71-.7h-7.16a.7.7,0,0,0-.71.7V77.4a.71.71,0,0,0,.71.71h7.24a.7.7,0,0,0,.7-.71V65c0-14.8,5.91-17,9.44-17a11,11,0,0,1,4.33.9.72.72,0,0,0,.61,0,.7.7,0,0,0,.36-.48l1.42-7.11A.71.71,0,0,0,460.15,40.5Z"
                      />
                      <path
                        className="cls-1"
                        d="M499.88,40.68a.69.69,0,0,0-.59-.31h-7.71a.72.72,0,0,0-.66.45L481.59,65l-9.42-24.18a.72.72,0,0,0-.66-.45h-7.86a.69.69,0,0,0-.58.31.7.7,0,0,0-.07.66l14,34.38-7.73,20.09a.71.71,0,0,0,.66,1h7.5a.69.69,0,0,0,.65-.45l21.86-55A.69.69,0,0,0,499.88,40.68Z"
                      />
                      <path
                        className="cls-1"
                        d="M97.91,28.11A40.38,40.38,0,0,0,59.73,0,39.62,39.62,0,0,0,24.6,20.87a29.88,29.88,0,0,0-7.21,56.56l.75.34h.05v-8.5a22.29,22.29,0,0,1,9.29-41.16l2.1-.22L30.5,26A32.15,32.15,0,0,1,59.73,7.57a32.7,32.7,0,0,1,31.55,25L92,35.43l3,0a18.53,18.53,0,0,1,18.15,18.46c0,7.05-4.07,12.82-11,15.74v8.06l.5-.16c11.14-3.65,18.06-12.71,18.06-23.64A26.19,26.19,0,0,0,97.91,28.11Z"
                      />
                      <path
                        className="cls-1"
                        d="M45.07,76.79l1.66,1.66a.33.33,0,0,1-.23.56H33.4a6,6,0,0,1-6-6V47.57a.33.33,0,0,0-.33-.33H24.27a.33.33,0,0,1-.24-.56L35.15,35.56a.33.33,0,0,1,.47,0L46.73,46.68a.33.33,0,0,1-.23.56H43.66a.34.34,0,0,0-.34.33v25A6,6,0,0,0,45.07,76.79Z"
                      />
                      <path
                        className="cls-1"
                        d="M69.64,76.79l1.67,1.66a.33.33,0,0,1-.24.56H58a6,6,0,0,1-6-6V54a.34.34,0,0,0-.33-.34H48.84a.33.33,0,0,1-.23-.56L59.72,42a.33.33,0,0,1,.47,0L71.31,53.08a.33.33,0,0,1-.24.56H68.23a.34.34,0,0,0-.33.34V72.57A6,6,0,0,0,69.64,76.79Z"
                      />
                      <path
                        className="cls-1"
                        d="M94.22,76.79l1.66,1.66a.33.33,0,0,1-.23.56H82.54a6,6,0,0,1-6-6V60.38a.33.33,0,0,0-.33-.33H73.41a.33.33,0,0,1-.23-.57L84.3,48.37a.32.32,0,0,1,.46,0L95.88,59.48a.33.33,0,0,1-.23.57H92.8a.33.33,0,0,0-.33.33V72.57A6,6,0,0,0,94.22,76.79Z"
                      />
                    </g>
                  </g>
                </svg>
              </a>
            </div>
            <button
              className="navbar-toggle collapsed"
              data-target="#site-main-nav"
              data-toggle="collapse"
              type="button"
              aria-label="navigation hamburger"
            >
              <span id="navbar-toggler" className="navbar-toggler-icon">
                <span id="nav-icon">
                  <span />
                  <span />
                  <span />
                  <span />
                </span>
              </span>
            </button>
          </div>
          <div className="collapse navbar-collapse" id="site-main-nav">
            <div className="header-section">
              <div id="mainMenu" className="menu-main-menu-2023-container">
                <ul id="menu-main-menu-2023" className="navbar-nav">
                  <li
                    id="menu-item-27967"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-has-children menu-item-27967 nav-item dropdown first-level"
                  >
                    <a href="#" className="nav-link dropdown-toggle">
                      Platform
                      <span className="chevron" />
                      <span className="sub" />
                    </a>
                    <div className="dropdown-menu">
                      <div className="container-fluid inner depth-0">
                        <div className="dropdown-column has-children">
                          <div className="dropdown-header">
                            Developer
                            <span className="sub" />
                          </div>
                          <ul className="link-list">
                            <li className=" link-list-item has-description">
                              <div className="nav-icon">
                                <img
                                  width={78}
                                  height={78}
                                  src='https://cloudinary-marketing-res.cloudinary.com/image/upload/c_scale,w_80,h_80/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1693359054/PM_Icon_for_web.png?_i=AA'
                                  loading="lazy"
                                  alt="Icon"
                                  className="style-svg wp-image-28729"
                                  data-public-id="PM_Icon_for_web.png"
                                  data-format="png"
                                  data-transformations="c_scale,w_auto,dpr_auto/f_auto,q_auto"
                                  data-version={1693359054}
                                  data-responsive={1}
                                  data-size="78 78"
                                  data-delivery="upload"
                                  onload="CLDBind(this)"
                                />
                              </div>
                              <a href="https://cloudinary.com/products/programmable_media">
                                Programmable Media
                                <span className="chevron" />
                                <span className="sub">
                                  APIs to automate image and video lifecycles
                                </span>
                              </a>
                            </li>
                            <li className=" link-list-item has-description">
                              <div className="nav-icon">
                                <img
                                  width={72}
                                  height={66}
                                  src='https://cloudinary-marketing-res.cloudinary.com/image/upload/c_fill,g_auto,w_80,h_80/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1691439952/video-api-icon-new.png?_i=AA'
                                  loading="lazy"
                                  alt="Icon"
                                  className="style-svg wp-image-28609"
                                  data-public-id="video-api-icon-new.png"
                                  data-format="png"
                                  data-transformations="c_scale,w_auto,dpr_auto/f_auto,q_auto"
                                  data-version={1691439952}
                                  data-responsive={1}
                                  data-size="72 66"
                                  data-delivery="upload"
                                  onload="CLDBind(this)"
                                />
                              </div>
                              <a href="https://cloudinary.com/video_api">
                                Video API
                                <span className="chevron" />
                                <span className="sub">
                                  Add video to your website or app in minutes
                                </span>
                              </a>
                            </li>
                            <li className=" link-list-item has-description">
                              <div className="nav-icon">
                                <img
                                  width={75}
                                  height={69}
                                  src='https://cloudinary-marketing-res.cloudinary.com/image/upload/c_fill,g_auto,w_80,h_80/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1691440462/cld-ai-new.png?_i=AA'
                                  loading="lazy"
                                  alt="Icon"
                                  className="style-svg wp-image-28610"
                                  data-public-id="cld-ai-new.png"
                                  data-format="png"
                                  data-transformations="c_scale,w_auto,dpr_auto/f_auto,q_auto"
                                  data-version={1691440462}
                                  data-responsive={1}
                                  data-size="75 69"
                                  data-delivery="upload"
                                  onload="CLDBind(this)"
                                />
                              </div>
                              <a href="https://cloudinary.com/products/cloudinary_ai">
                                Cloudinary AI
                                <span className="chevron" />
                                <span className="sub">
                                  Generative AI and Machine Learning to speed
                                  creativity
                                </span>
                              </a>
                            </li>
                            <li className=" link-list-item has-description">
                              <div className="nav-icon">
                                <img
                                  width={30}
                                  height={30}
                                  src='https://cloudinary-marketing-res.cloudinary.com/image/upload/c_scale,w_80,h_80/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1691092859/website_2021/media-flow-nav.png?_i=AA'
                                  loading="lazy"
                                  alt="Icon"
                                  className="style-svg wp-image-28482"
                                  data-public-id="website_2021/media-flow-nav.png"
                                  data-format="png"
                                  data-transformations="c_scale,w_auto,dpr_auto/f_auto,q_auto"
                                  data-version={1691092859}
                                  data-responsive={1}
                                  data-size="30 30"
                                  data-delivery="upload"
                                  onload="CLDBind(this)"
                                />
                              </div>
                              <a
                                target="_blank"
                                href="https://home.mediaflows.cloudinary.com/?utm_source=website&utm_medium=platform-nagivation"
                              >
                                MediaFlows
                                <span className="chevron" />
                                <span className="sub">
                                  Low-code workflow automation for image &amp;
                                  video
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="dropdown-column has-children">
                          <div className="dropdown-header">
                            Digital Asset Management
                            <span className="sub" />
                          </div>
                          <ul className="link-list">
                            <li className=" link-list-item has-description">
                              <div className="nav-icon">
                                <img
                                  width={37}
                                  height={37}
                                  src='https://cloudinary-marketing-res.cloudinary.com/image/upload/c_scale,w_80,h_80/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1706285230/assets-icon-white.png?_i=AA'
                                  loading="lazy"
                                  alt="Icon"
                                  className="style-svg wp-image-31923"
                                  data-public-id="assets-icon-white.png"
                                  data-format="png"
                                  data-transformations="c_scale,w_auto,dpr_auto/f_auto,q_auto"
                                  data-version={1706285230}
                                  data-responsive={1}
                                  data-size="37 37"
                                  data-delivery="upload"
                                  onload="CLDBind(this)"
                                />
                              </div>
                              <a href="https://cloudinary.com/products/digital_asset_management">
                                Assets
                                <span className="chevron" />
                                <span className="sub">
                                  for Enterprise and Large Organizations
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="dropdown-column has-children">
                          <div className="dropdown-header">
                            BETA
                            <span className="sub" />
                          </div>
                          <ul className="link-list">
                            <li className=" link-list-item has-description">
                              <div className="nav-icon">
                                <img
                                  width={200}
                                  height={200}
                                  src="https://cloudinary-marketing-res.cloudinary.com/image/upload/c_scale,w_200,h_200/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1706755782/final-touch-icon-200.png?_i=AA"
                                  loading="lazy"
                                  alt="Icon"
                                  className="style-svg wp-image-32085"
                                  data-public-id="final-touch-icon-200.png"
                                  data-format="png"
                                  data-transformations="c_scale,w_auto,dpr_auto/f_auto,q_auto"
                                  data-version={1706755782}
                                  data-responsive={1}
                                  data-size="200 200"
                                  data-delivery="upload"
                                  onload="CLDBind(this)"
                                />
                              </div>
                              <a target="_blank" href="https://final-tou.ch/">
                                FinalTouch
                                <span className="chevron" />
                                <span className="sub">
                                  Create quality product photos with Gen-AI
                                </span>
                              </a>
                            </li>
                            <li className=" link-list-item has-description">
                              <div className="nav-icon">
                                <img
                                  width={24}
                                  height={24}
                                  src='https://cloudinary-marketing-res.cloudinary.com/image/upload/c_scale,w_80,h_80/c_scale,w_auto,dpr_auto/f_auto,q_auto/v1708013072/dimensions-icon.png?_i=AA'
                                  loading="lazy"
                                  alt="Icon"
                                  className="style-svg wp-image-32399"
                                  data-public-id="dimensions-icon.png"
                                  data-format="png"
                                  data-transformations="c_scale,w_auto,dpr_auto/f_auto,q_auto"
                                  data-version={1708013072}
                                  data-responsive={1}
                                  data-size="24 24"
                                  data-delivery="upload"
                                  onload="CLDBind(this)"
                                />
                              </div>
                              <a
                                target="_blank"
                                href="https://www.welcome.dimensions.cloudinary.com/?utm_source=cloudinary&utm_medium=console&utm_campaign=product-page"
                              >
                                Dimensions
                                <span className="chevron" />
                                <span className="sub">
                                  3D models transformed into stunning creatives
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    id="menu-item-27976"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-has-children menu-item-27976 nav-item dropdown first-level"
                  >
                    <a href="#" className="nav-link dropdown-toggle">
                      Solutions
                      <span className="chevron" />
                      <span className="sub" />
                    </a>
                    <div className="dropdown-menu">
                      <div className="container-fluid inner depth-0">
                        <div className="dropdown-column">
                          <div className="dropdown-header">
                            BY BUSINESS NEED
                            <span className="sub" />
                          </div>
                          <div className="description">
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/solutions/composable-architecture">
                                  Composable Architecture
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/content_velocity">
                                  Content Velocity
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/customization">
                                  Customization
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/headless-dam">
                                  Headless DAM
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/immersive_experiences">
                                  Immersive Experiences
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/solutions/omnichannel">
                                  Omnichannel Journeys
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/performance_optimization">
                                  Performance Optimization
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/video">Video</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="dropdown-column">
                          <div className="dropdown-header">
                            By Industry
                            <span className="sub" />
                          </div>
                          <div className="description">
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/solutions/ecommerce">E-commerce</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/industries/media_entertainment">
                                  Media &amp; Entertainment
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/industries/cloudinary-for-nonprofits">
                                  Non-Profits
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/retail">Retail</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/solutions/industries/travel_hospitality">
                                  Travel &amp; Hospitality
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    id="menu-item-27979"
                    className="menu-item menu-item-type-custom menu-item-object-custom current-menu-item menu-item-27979 nav-item first-level"
                  >
                    <a href="https://cloudinary.com/developers" className="nav-link">
                      Developers
                      <span className="chevron" />
                      <span className="sub" />
                    </a>
                  </li>
                  <li
                    id="menu-item-27980"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-has-children menu-item-27980 nav-item dropdown first-level"
                  >
                    <a href="#" className="nav-link dropdown-toggle">
                      Resources
                      <span className="chevron" />
                      <span className="sub" />
                    </a>
                    <div className="dropdown-menu">
                      <div className="container-fluid inner depth-0">
                        <div className="dropdown-column">
                          <div className="dropdown-header">
                            Learn
                            <span className="sub" />
                          </div>
                          <div className="description">
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/blog/">Blog</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/demos">Demos</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/documentation">Documentation</a>
                              </li>
                              <li>
                                <a href="https://training.cloudinary.com/">
                                  Education &amp; Training
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/glossary">Glossary</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/podcasts">Podcasts</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/resources">Resource Library</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="dropdown-column">
                          <div className="dropdown-header">
                            Connect
                            <span className="sub" />
                          </div>
                          <div className="description">
                            <div className="d-lg-flex">
                              <ul>
                                <li>
                                  <a href="https://community.cloudinary.com/">
                                    Community
                                  </a>
                                </li>
                                <li>
                                  <a href="https://cloudinary.com/roadmap">Roadmap</a>
                                </li>
                              </ul>
                              <div>
                                <div className="dropdown-header">Support</div>
                                <ul>
                                  <li>
                                    <a href="https://support.cloudinary.com/">
                                      Knowledge Base
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://support.cloudinary.com/">
                                      Technical Support
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="featured-content">
                          <span className="title">Tech Articles</span>
                          <div className="description">
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/guides/image-formats/automatically-change-format-to-jpg">
                                  Image Formats
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/video-formats/top-five-web-video-formats-of-2021">
                                  Video Formats
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/image-effects/how-to-create-a-progress-bar-for-asset-uploads">
                                  Image Effects
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/video-effects/simplify-mobile-video-optimization">
                                  Video Effects
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/digital-asset-management/what-is-digital-asset-management">
                                  Digital Asset Management
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/e-commerce-platform/select-the-right-e-commerce-platform-for-your-needs">
                                  E-Commerce Platform
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/front-end-development/front-end-development-the-complete-guide">
                                  Front-End Development
                                </a>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/guides/web-performance/web-performance-what-is-it-trends-and-insights-for-2023">
                                  Web Performance
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/responsive-images/4-techniques-for-creating-responsive-images-with-bootstrap">
                                  Responsive Images
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/bulk-image-resize/bulk-image-resize-in-css-javascript-python-java-node-js-and-other-languages">
                                  Bulk Image Resize
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/automatic-image-cropping/cropping-images-in-python-with-pillow-and-opencv">
                                  Automatic Image Cropping
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/marketing-videos/12-types-of-marketing-videos-and-10-ways-to-make-them-great">
                                  Marketing Videos
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/wordpress-plugin/optimize-video-for-wordpress-a-guide-for-seamless-performance">
                                  WordPress Plugin
                                </a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/guides/user-generated-content/user-generated-content-marketing-opportunity-of-the-century-and-its-only-the-beginning">
                                  User-Generated Content
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    id="menu-item-27984"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-has-children menu-item-27984 nav-item dropdown first-level"
                  >
                    <a href="#" className="nav-link dropdown-toggle">
                      About Us
                      <span className="chevron" />
                      <span className="sub" />
                    </a>
                    <div className="dropdown-menu">
                      <div className="container-fluid inner depth-0">
                        <div className="dropdown-column">
                          <div className="dropdown-header">
                            Cloudinary Overview
                            <span className="sub" />
                          </div>
                          <div className="description">
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/about">About Us</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/customers">Customers</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/partners">Partners</a>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                <a href="https://cloudinary.com/careers">Careers</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/newsroom">Newsroom</a>
                              </li>
                              <li>
                                <a href="https://cloudinary.com/labs">Labs</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="dropdown-column">
                          <div className="dropdown-header">
                            Upcoming Events
                            <span className="sub" />
                          </div>
                          <div className="description">
                            <div>
                              <p style={{ marginBottom: 10 }}>
                                <a href="https://cloudinary.com/events">
                                  <img
                                    decoding="async"
                                    loading="lazy"
                                    src="https://cloudinary-marketing-res.cloudinary.com/images/f_auto,q_auto/v1687885591/Events_Nav_Placeholder/Events_Nav_Placeholder-png?_i=AA"
                                    alt="Upcoming Events"
                                    width={240}
                                    height={168}
                                  />
                                </a>
                              </p>
                              <ul>
                                <li>
                                  <a href="https://cloudinary.com/events">See all events</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li
                    id="menu-item-27987"
                    className="menu-item menu-item-type-custom menu-item-object-custom menu-item-27987 nav-item first-level"
                  >
                    <a href="https://cloudinary.com/pricing" className="nav-link">
                      Pricing
                      <span className="chevron" />
                      <span className="sub" />
                    </a>
                  </li>
                </ul>
              </div>{" "}
              <div className="header-side-nav">
                <div className="header-side-nav-wrapper">
                  <div className="menu-top-right-menu-container">
                    <ul id="top-right-menu" className="menu">
                      <li
                        id="menu-item-16518"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-16518 nav-item first-level"
                      >
                        <a
                          href="https://cloudinary.com/contact"
                          className="nav-link"
                          onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website primary navigation','eventAction': 'navigate','eventLabel': 'Contact'})"
                        >
                          Contact
                          <span className="chevron" />
                          <span className="sub" />
                        </a>
                      </li>
                      <li
                        id="menu-item-13062"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13062 nav-item first-level"
                      >
                        <a
                          href="https://support.cloudinary.com/"
                          className="nav-link"
                          onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website primary navigation','eventAction': 'navigate','eventLabel': 'Support'})"
                        >
                          Support
                          <span className="chevron" />
                          <span className="sub" />
                        </a>
                      </li>
                      <li
                        id="menu-item-13074"
                        className="menu-item menu-item-type-custom menu-item-object-custom menu-item-13074 nav-item first-level"
                      >
                        <a
                          href="https://cloudinary.com/documentation"
                          className="nav-link"
                          onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website primary navigation','eventAction': 'navigate','eventLabel': 'Documentation'})"
                        >
                          Documentation
                          <span className="chevron" />
                          <span className="sub" />
                        </a>
                      </li>
                      <li
                        id="menu-item-13063"
                        className="-ce-ignore menu-item menu-item-type-custom menu-item-object-custom menu-item-13063 nav-item first-level"
                      >
                        <a
                          href="https://cloudinary.com/users/login"
                          className="nav-link"
                          onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website primary navigation','eventAction': 'navigate','eventLabel': 'Login'})"
                        >
                          Login
                          <span className="chevron" />
                          <span className="sub" />
                        </a>
                      </li>
                    </ul>
                  </div>{" "}
                </div>
                <div className="signup-block">
                  <a
                    className="btn btn-sm btn-default"
                    href="https://cloudinary.com/users/register_free"
                    onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website primary navigation','eventAction': 'sign up','eventLabel': 'intent to sign up'})"
                  >
                    sign up for free
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <div id="fixed-spacer" style={{ height: 0 }} />
</header>



</section>
        </>
    )
}

export default Header;
