import React from 'react';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function Spinner(props) {
  const { size, thickness, negativeColor } = props;

  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: negativeColor ? '#000' : '#cdcdcd'
        }}
        size={size | 16}
        thickness={thickness | 5}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: negativeColor ? '#cdcdcd' : '#000',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={size | 16}
        thickness={thickness | 5}
      />
    </Box>
  );
}

export default Spinner;
