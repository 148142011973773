import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../utils/store';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';
import RangeSlider from 'components/rangeSlider/RangeSlider';
import TextField from 'components/textField/TextField';

function VideoTransformationsControls(props) {
  const { t } = useTranslation();
  const { title, controls, updateVideoTransformations } = props;
  const { store, resetStore, updateStore } = useStore();

  const { videoTransformations, selectedAsset } = store;
  const [toggledControls, setToggledControls] = useState(
    Object.keys(videoTransformations).filter(key => videoTransformations[key].enabled)
  );

  useEffect(() => {
    setToggledControls(
      Object.keys(videoTransformations).filter(key => videoTransformations[key].enabled)
    );
  }, [videoTransformations]);

  const [dimensions, setDimensions] = useState([
    { type: 'x', value: 1920 },
    { type: 'y', value: 1080 }
  ]);

  const handleDimensionsChange = (input, val) => {
    let updatedList = dimensions.map(item => {
      if (item.type === input.type && val.length < 5) {
        return { ...item, value: val };
      }
      return item;
    });
    setDimensions(updatedList);
  };

  return (
    <div className="controls-section">
      <p className="title">{title}</p>
      <div className="actions">
        <button
          className="btn secondary"
          onClick={() => {
            resetStore();
            setTimeout(() => {
              updateStore({ selectedAsset });
            });
          }}
        >
          {t('resetTransformations')}
        </button>
        <button className="btn primary" onClick={updateVideoTransformations}>
          {t('update')}
        </button>
      </div>
      <div className="controls">
        {controls.map(control => (
          <div key={control.key} className="control-container">
            <ToggleSwitch key={control.key} control={control} />
            <div className="control">
              {control.type === 'slider' && (
                <RangeSlider
                  key={control.key}
                  control={control}
                  isDisabled={!toggledControls.includes(control.name)}
                />
              )}
              {control.type === 'dimensions' && (
                <div className="dimensions">
                  {dimensions.map(input => {
                    return (
                      <div key={input.type} className="input-container">
                        <TextField
                          value={input.value}
                          setValue={val => handleDimensionsChange(input, val)}
                          isNumber
                          control={control}
                        />
                        <div className="dimensions-unit">px</div>
                      </div>
                    );
                  })}
                </div>
              )}
              {control.type === 'rotation' && <div className="rotation"></div>}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default VideoTransformationsControls;
