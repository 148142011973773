import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SvgIconChildren() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
        <path
          d="M5.65299 7.51765C6.67264 7.51765 7.49922 6.69107 7.49922 5.67143C7.49922 4.65178 6.67264 3.8252 5.65299 3.8252C4.63335 3.8252 3.80676 4.65178 3.80676 5.67143C3.80676 6.69107 4.63335 7.51765 5.65299 7.51765Z"
          fill="#777777"
        />
        <path
          d="M3.02303 4.80885C3.38674 3.70296 4.42709 2.90216 5.65298 2.90216H10.3849C9.42529 1.24932 7.64045 0.132812 5.59529 0.132812C3.93137 0.132812 2.44008 0.873612 1.42419 2.03904L3.02303 4.80885Z"
          fill="#777777"
        />
        <path
          d="M6.21982 8.38289C6.03658 8.4212 5.84734 8.4415 5.65302 8.4415C4.62975 8.4415 3.7371 7.88256 3.25754 7.05545L3.25477 7.05683L0.831596 2.86035C0.342806 3.68562 0.0566406 4.64519 0.0566406 5.67216C0.0566406 8.3995 2.03949 10.6667 4.63806 11.1222L6.21982 8.38289Z"
          fill="#777777"
        />
        <path
          d="M7.71161 3.8252C8.15193 4.31583 8.42241 4.96155 8.42241 5.67143C8.42241 6.17545 8.28486 6.64716 8.04854 7.05471L8.05131 7.0561L5.65491 11.2069C8.68134 11.1746 11.1341 8.70524 11.1341 5.67143C11.1341 5.0234 11.0164 4.40353 10.811 3.8252H7.71161Z"
          fill="#777777"
        />
      </svg>
    </SvgIcon>
  );
}
