import React from 'react';
import { useTranslation } from 'react-i18next';
import './LoaderComponent.scss';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CustomSpinner(props) {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: '#cdcdcd'
        }}
        size={16}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: '#000',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round'
          }
        }}
        size={16}
        thickness={5}
        {...props}
      />
    </Box>
  );
}

function LoaderComponent() {
  const { t } = useTranslation();

  return (
    <div className="loading-asset">
      <CustomSpinner />
      <div className="loading-text">{t('generatingVideo')}</div>
    </div>
  );
}

export default LoaderComponent;
