import React, { useEffect, useRef } from 'react';

const Player = props => {
  const { publicId, config, publicIds, endScreenPlaylist, alwaysOnPlaylist } = props;
  const playerRef = useRef(null);
  const vpRef = useRef(null);
  const isPlaylistLayoutDisposed = useRef(false);
  useEffect(() => {
    if (playerRef.current) {
      const playlistWidget =
        publicIds && publicIds.length > 0
          ? { direction: 'horizontal', total: publicIds.length }
          : {};

      vpRef.current = window.cloudinary.videoPlayer(playerRef.current, {
        cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
        transformation: {
          height: 540
        },
        playlistWidget
      });
    }
  }, [playerRef]);
  const configStr = JSON.stringify(config);
  useEffect(() => {
    if (vpRef.current) {
      if (publicIds && publicIds.length > 0) {
        vpRef.current.playlist(publicIds, {
          repeat: true,
          presentUpcoming: 5,
          autoAdvance: false
        });
        return;
      }
      if (publicId) {
        vpRef.current.source(publicId, config);
      }
    }
  }, [publicId, configStr, publicIds]);
  useEffect(() => {
    if (!vpRef.current) {
      return;
    }
    vpRef.current.autoShowRecommendations(endScreenPlaylist);
  }, [endScreenPlaylist]);
  useEffect(() => {
    if (!publicIds?.length) {
      return;
    }
    if (!alwaysOnPlaylist) {
      vpRef.current.playlistWidget().getLayout().removeLayout();
      vpRef.current.playlistWidget().getLayout().dispose();
      isPlaylistLayoutDisposed.current = true;
      return;
    }
    if (isPlaylistLayoutDisposed.current) {
      vpRef.current.playlistWidget().render();
      isPlaylistLayoutDisposed.current = false;
    }
  }, [alwaysOnPlaylist, publicIds]);
  return (
    <>
      <video id="cld-player" ref={playerRef} className="cld-video-player cld-fluid" controls />
    </>
  );
};

export default React.memo(Player);
