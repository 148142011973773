import React from 'react';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';
import SelectInput from 'components/selectInput/SelectInput';
import TextField from 'components/textField/TextField';
import { useStore } from '../../utils/store';

function VideoInteractivityControls(props) {
  const { t } = useTranslation();
  const { title, controls, updateConfig } = props;
  const { store, updateStore } = useStore();
  const { hotSpots } = store;
  const handleChange = (key, value) => {
    updateStore({
      hotSpots: {
        ...hotSpots,
        [key]: {
          ...hotSpots[key],
          enabled: value
        }
      }
    });
  };
  const getValue = key => {
    return hotSpots[key].enabled;
  };

  const handleSelectChange = (key, value) => {
    updateStore({
      hotSpots: {
        ...hotSpots,
        [key]: {
          ...hotSpots[key],
          effect: value
        }
      }
    });
  };

  const getSelectValue = key => {
    return hotSpots[key].effect;
  };

  const getUrlValue = key => {
    return hotSpots[key].url;
  };

  const handleUrlChange = (key, value) => {
    updateStore({
      hotSpots: {
        ...hotSpots,
        [key]: {
          ...hotSpots[key],
          url: value
        }
      }
    });
  };

  return (
    <div className="controls-section">
      <p className="title">{title}</p>
      <div className="controls">
        {controls.map(control => (
          <div key={control.storeKey} className="control-container">
            <ToggleSwitch
              key={control.storeKey}
              control={control}
              handleChange={handleChange}
              handledValue={getValue(control.storeKey)}
            />
            <div className="fields">
              <SelectInput
                handledValue={getSelectValue(control.storeKey)}
                handleChange={val => handleSelectChange(control.storeKey, val)}
                key={control.storeKey}
                control={control.selectControl}
              />
              {getSelectValue(control.storeKey) === 'url' && (
                <TextField
                  handleChange={val => handleUrlChange(control.storeKey, val)}
                  isNumber={false}
                  handledValue={getUrlValue(control.storeKey)}
                  placeholder={t(control.textInputPlaceHolder)}
                  control={control.textControl}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <button className="btn primary apply-button" onClick={updateConfig}>
        {t('apply')}
      </button>
    </div>
  );
}

export default VideoInteractivityControls;
