import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SvgIconChildren() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 13 12">
        <path
          d="M6.51769 11.2102C3.46357 11.2102 0.979004 8.72563 0.979004 5.6715C0.979004 2.61738 3.46357 0.132812 6.51769 0.132812C9.57182 0.132812 12.0564 2.61738 12.0564 5.6715C12.0564 8.72563 9.57182 11.2102 6.51769 11.2102ZM6.51769 1.05593C3.97267 1.05593 1.90212 3.12647 1.90212 5.6715C1.90212 8.21653 3.97267 10.2871 6.51769 10.2871C9.06272 10.2871 11.1333 8.21653 11.1333 5.6715C11.1333 3.12647 9.06272 1.05593 6.51769 1.05593Z"
          fill="#777777"
        />
        <path
          d="M9.28698 2.90234L5.82022 4.98443L3.74829 8.44103L7.22243 6.36587L9.28698 2.90234Z"
          fill="#777777"
        />
      </svg>
    </SvgIcon>
  );
}
