import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import AiZoompanControls from './AiZoompanControls';
import './AiZoompanPage.scss';
import Image from 'components/image/Image';
import LoaderComponent from 'components/loaderComponent/LoaderComponent';
import { useStore } from '../../utils/store';
import { cld } from '../../utils/cld';
import usePoll from '../../hooks/usePoll';
import { fit } from '@cloudinary/url-gen/actions/resize';

const photoList = ['samples/paris_zp_mtut6u', 'samples/working_zp_eare9u', 'samples/fasion_zp_qxs25d', 'samples/zp_lakehouse_gscfmh'];
const aiZoompanControls = [
  {
    key: 1,
    type: 'toggleSwitch',
    name: 'autoZoompan',
    label: 'aiZoompan.controls.autoZoompan',
    storeKey: 'autoZoomPan'
  },
  {
    key: 2,
    type: 'select',
    name: 'zoomType',
    selectOptions: [
      { name: 'Zoom in', value: 'in' },
      { name: 'Zoom out', value: 'out' }
    ],
    storeKey: 'zoomType'
  }
];
const pageStrings = {
  title: 'aiZoompan.title',
  subtitle: 'aiZoompan.subtitle',
  assetSelectTitle: 'aiZoompan.assetSelectTitle',
  controlsTitle: 'aiZoompan.controlsTitle'
};

const getDynamicPageStrings = autoZoomPan => ({
  ...pageStrings,
  assetSelectSubtitle: autoZoomPan
    ? 'aiZoompan.assetSelectSubtitle.autoMode'
    : 'aiZoompan.assetSelectSubtitle.manualMode'
});

function AiZoompanPage() {
  const { t } = useTranslation();
  const { store, updateStore, resetStore } = useStore();
  const [isLoadingAsset, setIsLoadingAsset] = useState(false);
  const { setUrl, isReady } = usePoll();
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  const { autoZoomPan, zoomFrom, selectedAsset, zoomType } = store;
  const cldPhotoList = photoList.map(photo => {
    return cld.image(photo);
  });


  useEffect(() => {
    updateStore({ selectedAsset: cldPhotoList[0] });
    const currentAsset = document.getElementsByClassName('current-asset')[0];
    if (currentAsset) {
      currentAsset.remove();
    }
  }, []);

  const asset = !selectedAsset ? cldPhotoList[0] : selectedAsset; 
  const originalUrl = cld.image(asset.publicID).toURL();
  const zoompanImage = cld.image(asset.publicID.split('.')[0] + '.mp4');
  if (autoZoomPan) {
    zoompanImage.effect('e_zoompan:du_8;fps_40;to_(g_auto;zoom_3)').resize(fit().width(600));
  } else {
    const dir = zoomType === 'in' ? 'to' : 'from';

    zoompanImage
      .effect(`e_zoompan:du_8;fps_40;${dir}_(x_${zoomFrom.xPerc};y_${zoomFrom.yPerc};zoom_4.5);`);
  }
  const [myImage, setMyImage] = useState(zoompanImage);

  const createZoompanVideo = () => {
    setMyImage(zoompanImage);
    setIsLoadingAsset(true);
    setUrl(zoompanImage.toURL());
    console.log(zoompanImage.toURL());
  };
  useEffect(() => {
    if (!isReady) {
      return;
    }
    setIsLoadingAsset(false);
  }, [isReady]);
  useEffect(() => {
    if (myImage.toURL() !== zoompanImage.toURL()) {
      createZoompanVideo();
    }
  }, [selectedAsset]);

  const transformedURL = zoompanImage.toURL();



  const bgimg = <div class="bg-img"></div>;
  const tag = <div class="tag"><a href="https://cloudinary.com/demos?filter=videos">DEMOS</a> / Image to Video</div>;
  const title = <h1>Image to Video</h1>;
  const desc = <p>Make your static images come to life with dynamic video-like effects. </p>;
  const extra = <p class="extra">Results are generated in real time (can take up to few minutes).</p>;
  const docs = <div class="docs-parent"><p class="orange">Original</p><p Id="original"><a href={originalUrl} target="_blank">{originalUrl}</a></p><p class="orange">Transformed:</p><p><a href={transformedURL} target="_blank">{transformedURL}</a></p></div>;

  const links = <div className="links">Links: <a target="_blank" href="https://cloudinary.com/documentation/zoompan_effect_tutorial">Docs</a> | <a target="_blank" href="https://cloudinary.com/blog/add-motion-to-your-pictures">Blog</a></div>;


  const otherTitle = <h2 class="other-title">Other Video Demos</h2>;
  const other = 
    <div class="row grid">      
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-optimization">
          <article>
            <h3>Dynamic Video Transcoding</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.mp4" width="100%" loop="" autoplay="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/adaptive-bitrate">
          <article>
            <h3>Adaptive Bitrate Streaming</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://studio.cloudinary.com/">
          <article>
            <h3>Video Player Studio</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-previews">
          <article>
            <h3>Automatic Video Previews</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-smart-cropping">
          <article>
            <h3>Video Smart Cropping</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-transformations">
          <article>
            <h3>Video Transformations</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712358821/Video-Transformation-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712358821/Video-Transformation-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/image-to-video">
          <article>
            <h3>Image to Video</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712512097/zoom-pan-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712512097/zoom-pan-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-generation">
          <article>
            <h3>Video Generation</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712769476/videoAPI_video-gen-thumb-fashion" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712769476/videoAPI_video-gen-thumb-fashion.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://demo.cloudinary.com/uw">
          <article>
            <h3>Upload Widget</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
    </div>;


  return (
    <>
    <div className="hero-section">
      <div className="hero">
      { tag }
      { title }
      { desc }
      </div>
      

      <div className="page-container ai-zoompan-page">
        <PageTopSection
          assetsSelectType="photo"
          assets={cldPhotoList}
          strings={getDynamicPageStrings(autoZoomPan)}
          isAssetPointSelect={false}          
           controls={
            <AiZoompanControls
              controls={aiZoompanControls}
              title={t(pageStrings.controlsTitle)}
              zoomType={zoomType}
              autoZoomPan={autoZoomPan}
              createZoompanVideo={createZoompanVideo}
            />
          }
        />
      
      <div className="bottom-section">
        <PageTopSection
          assetsSelectType="photo"
          assets={cldPhotoList}
          strings={getDynamicPageStrings(autoZoomPan)}
          isAssetPointSelect={!store.autoZoomPan}
        />
          {isLoadingAsset ? <LoaderComponent /> : <video autoPlay muted controls Loop="true" src={zoompanImage.toURL()} type="video/mp4" />}      
        </div>

        { links }
        { docs }

        
      </div>

        {otherTitle}
        {other}
    </div>
    </>
  );
}




export default AiZoompanPage;

