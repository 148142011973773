import React from 'react';
import Input from '@mui/material/Input';
import './TextField.scss';
import { useStore } from '../../utils/store';

function TextField(props) {
  const { placeholder, control, isNumber, value: defVal, handleChange, handledValue } = props;
  const { store, updateStore } = useStore();
  const value = defVal ?? store[control.storeKey] ?? handledValue;
  const handle = event => {
    if (handleChange) {
      handleChange(event.target.value);
      return;
    }
    const intTest = /^[0-9\b]+$/;
    if (!isNumber) {
      updateStore({ [control.storeKey]: event.target.value });
    } else {
      if (event.target.value === '' || intTest.test(event.target.value)) {
        updateStore({ [control.storeKey]: event.target.value });
      }
    }
  };

  return (
    <div className="text-field-container">
      <Input
        disableUnderline
        fullWidth
        placeholder={placeholder || ''}
        value={value}
        onChange={handle}
      />
    </div>
  );
}

export default TextField;
