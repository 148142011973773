import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../utils/store';

const hotSpotsLabels = {
  hotSpot1: 1,
  hotSpot2: 2,
  hotSpot3: 3
};

function VideoPointSelect(props) {
  const { container, isInBounds, hotspots } = props;
  const { store, updateStore } = useStore();
  const [dragPoint, setDragPoint] = useState(null);

  const getClickPos = event => {
    if (dragPoint && isInBounds) {
      const rect = container.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      updateStore({
        hotSpots: {
          ...store.hotSpots,
          [dragPoint.key]: {
            ...store.hotSpots[dragPoint.key],
            x,
            y
          }
        }
      });
    }
  };

  useEffect(() => {
    if (!isInBounds && dragPoint) {
      setDragPoint(null);
    }
  }, [dragPoint, isInBounds, setDragPoint]);

  const boxRef = useRef();
  useEffect(() => {
    if (boxRef.current) {
      setTimeout(() => {
        updateStore({
          hotSpotContainerDimensions: {
            width: boxRef.current.clientWidth,
            height: boxRef.current.clientHeight
          }
        });
      }, 100);
    }
  }, [boxRef]);
  return (
    <div
      ref={boxRef}
      className="point-select-container"
      onMouseUp={e => {
        setDragPoint(null);
        getClickPos(e);
      }}
      onMouseMove={dragPoint ? getClickPos : null}
    >
      {hotspots.map(spot => (
        <div
          className="point-selection"
          key={spot.key}
          style={{
            left: `${spot.x}px`,
            top: `${spot.y}px`,
            display: spot.enabled ? 'flex' : 'none',
            zIndex: spot.key === dragPoint?.key ? 2 : 1
          }}
          onMouseDown={e => {
            e.preventDefault();
            setDragPoint(spot);
          }}
        >
          {hotSpotsLabels[spot.key]}
        </div>
      ))}
    </div>
  );
}

export default VideoPointSelect;
