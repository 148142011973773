import { Cloudinary } from '@cloudinary/url-gen';

export const cld = new Cloudinary({
  cloud: {
    cloudName: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
  },
  url: {
    secure: true
  }
});

export const cldCreateVideo = async (publicId, manifest) => {
  return await fetch(
    `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/video/create_video`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        public_id: publicId,
        resource_type: 'video',
        upload_preset: process.env.REACT_APP_CLOUDINARY_UNSIGNED_UPLOAD_PRESET,
        manifest_json: JSON.stringify(manifest)
      })
    }
  );
};


// export const cldVideoDuration = async (publicId) => {
//   return await fetch(
//     `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/resources/`,
//     {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify({
//         public_id: publicId,
//         resource_type: 'video'
//       })
//     }
//   );
// };
