import React from 'react';
import HomePage from 'pages/homePage/HomePage';
import AiCroppingPage from 'pages/aiCropping/AiCroppingPage';
import AutomatedVideoPage from 'pages/automatedVideo/AutomatedVideoPage';
import AiZoompanPage from 'pages/aiZoompan/AiZoompanPage';
import ImagesToVideoPage from 'pages/imagesToVideo/ImagesToVideoPage';
import VideoInteractivityPage from 'pages/videoInteractivity/VideoInteractivityPage';
import SmartIntegrationsPage from 'pages/smartIntegrations/SmartIntegrationsPage';
import VideoTransformationsPage from 'pages/videoTransformationsPage/VideoTransformationsPage';
import PlayerEngagementPage from 'pages/playerEngagement/PlayerEngagementPage';
import VideoOptimizationPage from 'pages/videoOptimization/VideoOptimizationPage';
import AdaptiveBitrate from 'pages/adaptiveBitrate/AdaptiveBitrate';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import './App.scss';
import './i18n';
import { Route, Routes } from 'react-router-dom';
function App() {
  return (
    <>
      <div className="App">
        <div className="main">
          <Header />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/image-to-video" element={<AiZoompanPage />} />
            <Route path="/video-generation" element={<ImagesToVideoPage />} />
            <Route path="/video-previews" element={<AutomatedVideoPage />} />
            <Route path="/video-smart-cropping" element={<AiCroppingPage />} />
            <Route path="/smart-integrations" element={<SmartIntegrationsPage />} />
            <Route path="/player-flexibility" element={<AiCroppingPage />} />
            <Route path="/video-transformations" element={<VideoTransformationsPage />} />
            <Route path="/player-engegament" element={<PlayerEngagementPage />} />
            <Route path="/video-interactivity" element={<VideoInteractivityPage />} />
            <Route path="/video-optimization" element={<VideoOptimizationPage />} />

            <Route path="/adaptive-bitrate" element={<AdaptiveBitrate />} />
            {/*<Route path="*" element={<Navigate to="/" />} />*/}
          </Routes>          
          <Footer />
        </div>        
      </div>
    </>
  );
}

export default App;
