import React from 'react';
import { useTranslation } from 'react-i18next';
import RangeSlider from 'components/rangeSlider/RangeSlider';
import SelectInput from 'components/selectInput/SelectInput';

function ImagesToVideoControls(props) {
  const { title, controls, createVideo, selectedAssets } = props;
  const { t } = useTranslation();

  const handleOnChange = (control, value) => {};

  return (
    <div className="controls-section">
      <p className="title">{title}</p>
      <div className="controls">
        {controls.map(control => {
          if (control.type === 'slider') {
            return (
              <RangeSlider
                key={control.key}
                control={control}
                onChange={(control, value) => handleOnChange(control, value)}
              />
            );
          }
          if (control.type === 'select') {
            return (
              <SelectInput
                key={control.key}
                control={control}
                onChange={(control, value) => handleOnChange(control, value)}
              />
            );
          }
          return null;
        })}
        <button className="btn primary" onClick={createVideo} disabled={selectedAssets.length < 3}>
          {t('Generate')}
        </button>
      </div>
    </div>
  );
}

export default ImagesToVideoControls;
