import React from 'react';
import { AdvancedImage } from '@cloudinary/react';

const Image = props => {
  const { cldImg } = props;
  if (!cldImg) {
    return null;
  }
  return <AdvancedImage cldImg={cldImg} />;
};

export default React.memo(Image);
