import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../utils/store';
import clsx from 'clsx';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import AiCroppingControls from './AiCroppingControls';
import { cld } from '../../utils/cld';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { autoGravity } from '@cloudinary/url-gen/qualifiers/gravity';

import './AiCroppingPage.scss';
import ScreenImage from 'assets/ai-driven-cropping-page-images/device-screen-image.png';
import HorizontalScreenImage from 'assets/ai-driven-cropping-page-images/device-screen-horiz-image.png';
import Facebook from 'assets/ai-driven-cropping-page-images/facebook.png';
import Instagram from 'assets/ai-driven-cropping-page-images/instagram.png';
import Youtube from 'assets/ai-driven-cropping-page-images/youtube.png';
import { useMemo } from 'react';
import CropVideo from '../../components/cropVideo/CropVideo';

const videoList = [
  'samples/surfer_crop_rm6b19',
  'samples/snowboard_crop_mha2ac',
  'samples/boat_crop_dakgqt',
];

const pageStrings = {
  title: 'aiDriven.title',
  subtitle: 'aiDriven.subtitle',
  detailed: 'aiDriven.detailed',
  assetSelectTitle: 'aiDriven.assetSelectTitle',
  controlsTitle: 'aiDriven.controlsTitle'
};

function AiCroppingPage() {
  const { t } = useTranslation();
  const { store, updateStore, resetStore } = useStore();
  const { selectedAsset } = store;
  const [isAllReady, setIsAllReady] = useState(false);
  const readyVideosCount = useRef(0);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  useEffect(() => {
    updateStore({ selectedAsset: videoList[0] });
  }, []);
  const currentAsset = selectedAsset ? selectedAsset : videoList[0];

  const [isInit, setIsInit] = useState(false);
  const [deviceSize, setDeviceSize] = useState({ width: 300, height: 150 });
  useEffect(() => {
    const { innerWidth: width } = window;
    if (!isInit) {
      if (width <= 876) {
        setDeviceSize({ width: 150, height: 300 });
      } else {
        setDeviceSize({ width: 192, height: 384 });
      }
      setIsInit(true);
    }
  }, [isInit]);

  const facebookCropped = useMemo(() => {
    return cld
      .video(currentAsset)
      .resize(fill().width(deviceSize.width).aspectRatio(1).gravity(autoGravity()));
  }, [currentAsset, deviceSize]);

  const myVideoVertical = useMemo(() => {
    return cld
      .video(currentAsset)
      .resize(fill().width(deviceSize.width).height(deviceSize.height).gravity(autoGravity()));
  }, [currentAsset, deviceSize]);
  const myVideoVerticalUncropped = useMemo(() => {
    return cld.video(currentAsset).resize(fill().width(deviceSize.width).height(deviceSize.height));
  }, [currentAsset, deviceSize]);

  const myVideoHorizontal = useMemo(
    () =>
      cld
        .video(currentAsset)
        .resize(fill().width(deviceSize.height).height(deviceSize.width).gravity(autoGravity())),
    [currentAsset, deviceSize]
  );
  const myVideoHorizontalUncropped = useMemo(() => {
    return cld.video(currentAsset).resize(fill().width(deviceSize.height).height(deviceSize.width));
  }, [currentAsset, deviceSize]);

  const [play, setPlay] = useState(false);
  useEffect(() => {
    setPlay(false);

    readyVideosCount.current = 0;
  }, [currentAsset]);
  if (!isInit) {
    return null;
  }

  const handleIsReady = () => {
    readyVideosCount.current += 1;
    setIsAllReady(readyVideosCount.current === 6);
  };
  const originalURL = "https://res.cloudinary.com/videoapi-demo/video/upload/v1/" + currentAsset;
  const squareURL = "https://res.cloudinary.com/videoapi-demo/video/upload/ar_1:1,c_fill,g_auto,w_192/v1/" + currentAsset;
  const portraitURL = "https://res.cloudinary.com/videoapi-demo/video/upload/c_fill,g_auto,ar_1:2,w_192/v1/" + currentAsset;
  const landscapeURL = "https://res.cloudinary.com/videoapi-demo/video/upload/c_fill,g_auto,ar_2:1,h_192/" + currentAsset;

  const bgimg = <div class="bg-img"></div>;
  const tag = <div class="tag"><a href="https://cloudinary.com/demos?filter=videos">DEMOS</a> / Video Smart Cropping</div>;
  const title = <h1>Video Smart Cropping</h1>;
  const desc = <p>Intelligently crop, resize videos and maintain focus based on content, device and channel. </p>;
  const extra = <p class="extra">Results are generated in real time (can take up to few minutes).</p>;
  const docs = <div class="docs-parent">
  <p class="orange">Original</p><p><a href={originalURL} target="_blank">https://res.cloudinary.com/videoapi-demo/video/upload/v1/{currentAsset}</a></p>
  <p class="orange">Transformed (Square 1:1):</p><p><a href={squareURL} target="_blank">https://res.cloudinary.com/videoapi-demo/video/upload/ar_1:1,c_fill,g_auto,w_192/v1/{currentAsset}</a></p>
  <p class="orange">Transformed (Portrait 1:2):</p><p><a href={portraitURL} target="_blank">https://res.cloudinary.com/videoapi-demo/video/upload/c_fill,g_auto,ar_1:2,w_192/v1/{currentAsset}</a></p>
  <p class="orange">Transformed (Landscape 2:1):</p><p><a href={landscapeURL} target="_blank">https://res.cloudinary.com/videoapi-demo/video/upload/c_fill,g_auto,ar_2:1,h_192/v1/{currentAsset}</a></p></div>;

  const links =  <div className="links">Links: <a target="_blank" href="https://cloudinary.com/documentation/video_resizing_and_cropping#automatic_gravity_for_crops_g_auto">Docs</a> | <a target="_blank" href="https://cloudinary.com/guides/video-effects/smart-crop-video">Blog</a></div>;


  const toggles = document.querySelectorAll('.toggles');
  let visible;
  // Iterate over each 'toggles' element
  toggles.forEach(toggle => {
      // Add a click event listener to each child element of the 'toggles' element
      toggle.addEventListener('click', (event) => {
          // Check if the clicked element is a direct child of the current 'toggles' element
          if (event.target.parentElement === toggle) {
              // Log the class of the clicked child element

              let visible = event.target.id;            

              document.querySelectorAll('.app-container').forEach(element => {
                  element.style.display = 'none';
              });

              // Show the element with the specified class name
              const targetElement = document.querySelector(`.app-container.${visible}`);
              if (targetElement) {
                  targetElement.style.display = 'block';
              }

              const togglesChildren = document.querySelectorAll('.toggles > div');
              // Loop through each child and remove the .active class
              togglesChildren.forEach(child => {
                  child.classList.remove('active');
              });

              // Add 'active' class to the clicked element
              event.target.classList.add('active');
          }
      });
  });


  const otherTitle = <h2 class="other-title">Other Video Demos</h2>;
  const other = 
    <div class="row grid">      
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-optimization">
          <article>
            <h3>Dynamic Video Transcoding</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.mp4" width="100%" loop="" autoplay="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/adaptive-bitrate">
          <article>
            <h3>Adaptive Bitrate Streaming</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://studio.cloudinary.com/">
          <article>
            <h3>Video Player Studio</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-previews">
          <article>
            <h3>Automatic Video Previews</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-smart-cropping">
          <article>
            <h3>Video Smart Cropping</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-transformations">
          <article>
            <h3>Video Transformations</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712358821/Video-Transformation-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712358821/Video-Transformation-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/image-to-video">
          <article>
            <h3>Image to Video</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712512097/zoom-pan-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712512097/zoom-pan-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-generation">
          <article>
            <h3>Video Generation</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712769476/videoAPI_video-gen-thumb-fashion" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712769476/videoAPI_video-gen-thumb-fashion.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://demo.cloudinary.com/uw">
          <article>
            <h3>Upload Widget</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
    </div>;


  return (
    <div className="page-container ai-cropping-page">

      <div className="hero">
      { tag }
      { title }
      { desc }
      </div>

      <div className="top">
      <PageTopSection
        assetsSelectType="video"
        assets={videoList}
        strings={pageStrings}
        controls={<AiCroppingControls controls={[]} title={t(pageStrings.controlsTitle)} />}
      />
      </div>
      <div className="bottom-section">
        <div className={clsx(['apps', 'withoutCropping'])}>
          <div className="app-container square">
            <div className="devices">
              <div className="device-container">
                <img src={Facebook} alt="" className="app-header" />
                <img src={ScreenImage} alt="" className="screen-image" />
                <div className="video-container facebook">
                  <CropVideo
                    setPlay={setPlay}
                    play={play}
                    cldVid={facebookCropped}
                    onReady={handleIsReady}
                    isAllReady={isAllReady}
                  ></CropVideo>
                </div>
              </div>
              <div className={clsx(['device-container', 'no-cropping'])}>
                <div className="app-header text">{t('withoutAiCropping')}</div>
                <img src={ScreenImage} alt="" className="screen-image" />
                <div className="video-container">
                  <CropVideo
                    setPlay={setPlay}
                    play={play}
                    cldVid={myVideoVerticalUncropped}
                    onReady={handleIsReady}
                    isAllReady={isAllReady}
                  ></CropVideo>
                </div>
              </div>
            </div>
          </div>
          <div className="app-container vertical hide">
            <div className="devices">
              <div className="device-container">
                <img src={Instagram} alt="" className="app-header" />
                <img src={ScreenImage} alt="" className="screen-image" />
                <div className="video-container">
                  <CropVideo
                    setPlay={setPlay}
                    play={play}
                    cldVid={myVideoVertical}
                    onReady={handleIsReady}
                    isAllReady={isAllReady}
                  ></CropVideo>
                </div>
              </div>
              <div className={clsx(['device-container', 'no-cropping'])}>
                <div className="app-header text">{t('withoutAiCropping')}</div>
                <img src={ScreenImage} alt="" className="screen-image" />
                <div className="video-container">
                  <CropVideo
                    setPlay={setPlay}
                    play={play}
                    cldVid={myVideoVerticalUncropped}
                    onReady={handleIsReady}
                    isAllReady={isAllReady}
                  ></CropVideo>
                </div>
              </div>
            </div>
          </div>
          <div className="app-container horizontal hide">
            <div className="devices">
              <div className="device-container">
                <img src={Youtube} alt="" className="app-header" />
                <img src={HorizontalScreenImage} alt="" className="screen-image" />
                <div className="video-container">
                  <CropVideo
                    setPlay={setPlay}
                    play={play}
                    cldVid={myVideoHorizontal}
                    onReady={handleIsReady}
                    isAllReady={isAllReady}
                  ></CropVideo>
                </div>
              </div>
              <div className={clsx(['device-container', 'no-cropping'])}>
                <div className="app-header text">{t('withoutAiCropping')}</div>
                <img src={HorizontalScreenImage} alt="" className="screen-image" />
                <div className="video-container">
                  <CropVideo
                    setPlay={setPlay}
                    play={play}
                    cldVid={myVideoHorizontalUncropped}
                    onReady={handleIsReady}
                    isAllReady={isAllReady}
                  ></CropVideo>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="toggle-parent">
          <p>Select desired aspect ratio:</p>
          <div className="toggles">
            <div id="square" className="active">Square 1:1</div>
            <div id="vertical">Photo Portrait 1:2</div>
            <div id="horizontal">Landscape</div>
          </div>
          </div>          
      </div>      
      <div>

      { links }

      { docs }

      {otherTitle}
      {other}

      
      </div>
    </div>
  );
}

export default AiCroppingPage;
