import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SvgIconChildren() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 11">
        <path
          d="M5.55664 0C3.95064 0 2.50781 0.696208 1.50177 1.79712C1.26389 1.45658 1.20202 1.04362 1.20202 1.04362C1.20202 1.04362 0.422849 1.62479 0.631849 3.03554C0.631849 3.03554 0.629557 3.05388 0.624974 3.08046C0.264724 3.81196 0.0566406 4.631 0.0566406 5.5C0.0566406 8.53279 2.52385 11 5.55664 11C8.58943 11 11.0566 8.53279 11.0566 5.5C11.0566 2.46721 8.58943 0 5.55664 0ZM9.36356 2.95121C9.56981 6.56746 6.79506 9.38758 3.97585 7.32875C3.98043 7.32967 5.21977 7.34342 5.6616 7.106C5.94027 6.95613 6.29639 6.66004 6.46827 6.688C6.74693 6.72925 6.81247 6.50513 6.67222 6.34425C6.51135 6.15908 6.0851 5.80938 5.5901 6.04404C4.2316 6.68754 3.32547 5.73604 3.48268 5.17871C3.51202 5.07375 3.62431 4.93533 3.67152 4.92617C3.9016 4.70296 5.23122 3.828 5.3976 3.42192C5.43702 3.32567 5.48147 3.09971 5.35268 3.0525C5.1391 3.00163 4.63081 2.99979 4.44289 3.00117C4.08493 3.00437 3.84477 2.78117 3.86081 2.55888C3.88097 2.28158 4.07439 1.936 4.62347 1.48317C3.68068 1.34567 3.13664 2.09779 3.13618 2.09871C2.95972 2.05471 2.74981 2.04463 2.54997 2.04921C3.35572 1.34613 4.40577 0.916667 5.55664 0.916667C7.1411 0.916667 8.53993 1.72563 9.36356 2.95121Z"
          fill="#777777"
        />
      </svg>
    </SvgIcon>
  );
}
