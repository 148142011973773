import React, { useRef, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import VideoOptimizationControls from './VideoOptimizationControls';
import './AdaptiveBitrate.scss';
import { useStore } from '../../utils/store';
import { cld } from '../../utils/cld';
import { videoCodec } from '@cloudinary/url-gen/actions/transcode';
import { h265, vp9 } from '@cloudinary/url-gen/qualifiers/videoCodec';
import { calculatePercentage, getVideoSize } from './helper';
import AdaptiveStreamingPlayer from '../../components/adaptiveStreamingPlayer/AdaptiveStreamingPlayer';
import usePoll from '../../hooks/usePoll';

const videoList = [
  'samples/travel_abr_icyvut',
  'samples/unboxing_abr_dy9pkk',
  'samples/chef_abr_zohm46',
  'samples/fitness_abr_jkljqy'
];

const browsersSupport = {
  h264mp4: ['chrome', 'explorer', 'firefox', 'opera', 'safari'],
  h265mp4: ['safari', 'explorer'],
  vp9Webm: ['chrome', 'explorer', 'firefox', 'opera'],
  av1Webm: ['chrome', 'explorer', 'firefox', 'opera']
};

const videoOptimizationControls = {
  formats: [
    {
      key: 'original',
      name: 'Original',
      transcode: '',
      info: {
        format: 'MP4',
        resolution: '1920x1080',
        browsers: [],
        size: 12.4
      },
      saveValue: 20,
      loaded: false
    },
    {
      key: 'h264mp4',
      transcode: 'h264mp4',
      name: 'MP4/H.264',
      info: {
        format: 'MP4',
        resolution: '1920x1080',
        browsers: browsersSupport.h264mp4,
        size: 12.4
      },
      loaded: false,
      saveValue: 10
    },
    {
      key: 'h265mp4',
      name: 'MP4/H.265',
      info: {
        format: 'MP4',
        resolution: '1920x1080',
        browsers: browsersSupport.h265mp4,
        size: 12.4
      },
      loaded: false,
      saveValue: 90
    },
    {
      key: 'vp9Webm',
      transcode: 'vp9Webm',
      name: 'WEBM/VP9',
      info: {
        format: 'WEBM',
        resolution: '1920x1080',
        browsers: browsersSupport.vp9Webm,
        size: 12.4
      },
      loaded: false,
      saveValue: 70
    },
    {
      key: 'av1Webm',
      transcode: 'av1Webm',
      name: 'WEBM/AV1',
      info: {
        format: 'WEBM',
        resolution: '1920x1080',
        browsers: browsersSupport.av1Webm,
        size: 12.4
      },
      loaded: false,
      saveValue: 50
    }
  ],
  selectControl: {
    storeKey: 'streamingProfile',
    selectOptions: [
      { name: '4k', value: '4k' },
      { name: 'Full HD', value: 'full_hd' },
      { name: 'HD', value: 'hd' },
      { name: 'SD', value: 'sd' }
    ]
  }
};

const pageStrings = {
  title: 'optimizations.title',
  subtitle: 'optimizations.subtitle',
  assetSelectTitle: 'optimizations.assetSelectTitle',
  controlsTitle: 'optimizations.controlsTitle',
  controlsSubTitle: 'optimizations.controlsSubTitle',
  profileBarTitle: 'optimizations.profileBarTitle'
};

const originalState = {
  loaded: false,
  loading: false,
  size: 0,
  isOriginal: true,
  format: '',
  saving: 0
};

const originalDiffState = {
  loaded: false,
  loading: false,
  size: 0,
  saving: 0
};

const STREAMING_PROFILES_META = [
  {
    name: '4k',
    width: 3840,
    height: 2160
  },
  {
    name: '1440p',
    width: 2560,
    height: 1440
  },
  {
    name: '1080p',
    width: 1920,
    height: 1080
  },
  {
    name: '720p',
    width: 1280,
    height: 720
  }
];

function AdaptiveBitrate() {
  const [qualityLevels, setQualityLevels] = useState([]);
  const { store, updateStore, resetStore } = useStore();
  const { selectedAsset, streamingProfile } = store;
  const { t } = useTranslation();
  const curAsset = selectedAsset || videoList[0];
  const [original, setOriginal] = useState({ ...originalState });
  const [h264mp4, setH264mp4] = useState({ ...originalDiffState });
  const [h265mp4, setH265mp4] = useState({ ...originalDiffState });
  const [vp9Webm, setVp9Webm] = useState({ ...originalDiffState });
  const [av1Webm, setAv1Webm] = useState({ ...originalDiffState });
  const [activeResolution, setActiveResolution] = useState();
  const [selectedResolution, setSelectedResolution] = useState();
  const ref = useRef([]);
  const { setUrl: setUrlOriginal, isReady: isReadyOriginal } = usePoll();
  const { setUrl: setUrlH264mp4, isReady: isReadyH264mp4 } = usePoll();
  const { setUrl: setUrlH265mp4, isReady: isReadyH265mp4 } = usePoll();
  const { setUrl: setUrlVp9Webm, isReady: isReadyVp9Webm } = usePoll();
  const { setUrl: setUrlAv1Webm, isReady: isReadyAv1Webm } = usePoll();
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  useEffect(() => {
    const fetchSizeOriginal = async url => {
      setOriginal({
        ...original,
        loading: true
      });

      const { size } = await getVideoSize(url);
      if (size === null) {
        setUrlOriginal('');
        setTimeout(() => {
          setUrlOriginal(url);
        });
        return;
      }

      setOriginal({
        ...original,
        loaded: true,
        loading: false,
        size,
        format: 'MP4'
      });
      return size;
    };
    if (isReadyOriginal) {
      const originalUrl = cld.video(curAsset).toURL();
      fetchSizeOriginal(originalUrl);
    }
  }, [isReadyOriginal]);
  const h264mp4Url = cld.video(`${curAsset}.mp4`).quality('auto').toURL();
  const h265mp4Url = cld
    .video(`${curAsset}.mp4`)
    .transcode(videoCodec(h265()).addQualifier('q_auto'))
    .toURL();
  const vp9WebmUrl = cld
    .video(`${curAsset}.webm`)
    .transcode(videoCodec(vp9()).addQualifier('q_auto'))
    .toURL();
  // no av1 support in url-gen
  const av1WebmURL = cld
    .video(`${curAsset}.webm`)
    .transcode(videoCodec(vp9()).addQualifier('q_auto'))
    .toURL()
    .replace('vc_vp9', 'vc_av1');
  const fetchSize = async (url, setFunc, curVal) => {
    setFunc({
      ...curVal,
      loading: true
    });
    const { size } = await getVideoSize(url);
    if (size === null) {
      return null;
    }
    const { saving } = calculatePercentage(original.size, size);

    setFunc({
      ...curVal,
      loaded: true,
      loading: false,
      size,
      saving
    });
    return size;
  };

  useEffect(() => {
    const handle = async () => {
      const size = await fetchSize(h264mp4Url, setH264mp4, h264mp4);
      if (size === null) {
        setUrlH264mp4('');
        setTimeout(() => {
          setUrlH264mp4(h264mp4Url);
        });
        return;
      }
    };
    if (isReadyH264mp4) {
      handle();
    }
  }, [isReadyH264mp4]);

  useEffect(() => {
    const handle = async () => {
      const size = await fetchSize(h265mp4Url, setH265mp4, h265mp4);

      if (size === null) {
        setUrlH265mp4('');
        setTimeout(() => {
          setUrlH265mp4(h265mp4Url);
        });
        return;
      }
    };
    if (isReadyH265mp4) {
      handle();
    }
  }, [isReadyH265mp4]);

  useEffect(() => {
    const handle = async () => {
      const size = await fetchSize(vp9WebmUrl, setVp9Webm, vp9Webm);
      if (size === null) {
        setUrlVp9Webm('');
        setTimeout(() => {
          setUrlVp9Webm(vp9WebmUrl);
        });
        return;
      }
    };
    if (isReadyVp9Webm) {
      handle();
    }
  }, [isReadyVp9Webm]);

  useEffect(() => {
    const handle = async () => {
      const size = await fetchSize(av1WebmURL, setAv1Webm, av1Webm);

      if (size === null) {
        setUrlAv1Webm('');
        setTimeout(() => {
          setUrlAv1Webm(vp9WebmUrl);
        });
        return;
      }
    };
    if (isReadyAv1Webm) {
      handle();
    }
  }, [isReadyAv1Webm]);

  useEffect(() => {
    if (original.loaded) {
      setUrlH264mp4(h264mp4Url);
      setUrlH265mp4(h265mp4Url);
      setUrlVp9Webm(vp9WebmUrl);
      setUrlAv1Webm(av1WebmURL);
    }
  }, [original]);

  const resetState = () => {
    setOriginal({ ...originalState });
    setH264mp4({ ...originalDiffState });
    setH265mp4({ ...originalDiffState });
    setVp9Webm({ ...originalDiffState });
    setAv1Webm({ ...originalDiffState });
    setUrlOriginal('');
    setUrlH264mp4('');
    setUrlH265mp4('');
    setUrlVp9Webm('');
    setUrlAv1Webm('');
  };
  useEffect(() => {
    updateStore({ selectedAsset: videoList[0] });
  }, []);

  useEffect(() => {
    resetState();

    setUrlOriginal(cld.video(curAsset).toURL());
  }, [selectedAsset]);
  const values = {
    original,
    h264mp4,
    h265mp4,
    vp9Webm,
    av1Webm
  };
  const streamingProfilesList = qualityLevels;
  const handleSelectedResolutionChanged = width => {
    const profile = ref.current.find(profile => profile.width === width);
    if (!profile) {
      return;
    }
    setSelectedResolution(profile.name);
  };
  const handleVideoWidthChanged = width => {
    const profile = ref.current.find(profile => profile.width === width);
    if (!profile) {
      return;
    }
    setActiveResolution(profile.name);
    setSelectedResolution('');
  };

  const handleQualityLevelsChange = qualityLevels => {
    const qls = [];
    for (let i = 0; i < qualityLevels.length; i++) {
      const ql = qualityLevels[i];
      const meta = STREAMING_PROFILES_META.find(
        meta => meta.width === ql.width && meta.height === ql.height
      );

      const name = meta ? meta.name : `${ql.width}x${ql.height}`;
      qls.push({
        name,
        width: qualityLevels[i].width,
        height: qualityLevels[i].height
      });
    }
    setQualityLevels(qls);
    ref.current = qls;
  };

  const originalURL = cld.video(curAsset).toURL();
  const transformedURL = "https://res.cloudinary.com/videoapi-demo/video/upload/sp_auto/v1/" + curAsset + ".m3u8";

  const bgimg = <div class="bg-img"></div>;
  const tag = <div class="tag"><a href="https://cloudinary.com/demos?filter=videos">DEMOS</a> / Adaptive Bitrate Streaming</div>;
  const title = <h1>Adaptive Bitrate Streaming</h1>;
  const desc = <p>Encode video on-the-edge and adjust the quality real-time based on network speed for instant streaming and no buffering.</p>;
  const extra = <p class="extra">Results are generated in real time (can take up to few minutes).</p>
  const docs = <div class="docs-parent">
  <p class="orange">Original</p>
  
  <p><a href={originalURL} target="_blank">{originalURL}</a></p>
  <p class="orange">ABR</p>
  <p><a href={transformedURL} target="_blank">{transformedURL}</a></p>
  </div>
  const links = <div className="links">Links: <a target="_blank" href="https://cloudinary.com/documentation/adaptive_bitrate_streaming">Docs</a> | <a target="_blank" href="https://cloudinary.com/blog/no-more-buffering-best-practices-to-optimize-video">Blog</a> </div>;

  const otherTitle = <h2 class="other-title">Other Video Demos</h2>;
  const other = 
    <div class="row grid">      
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-optimization">
          <article>
            <h3>Dynamic Video Transcoding</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.mp4" width="100%" loop="" autoplay="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/adaptive-bitrate">
          <article>
            <h3>Adaptive Bitrate Streaming</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://studio.cloudinary.com/">
          <article>
            <h3>Video Player Studio</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-previews">
          <article>
            <h3>Automatic Video Previews</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-smart-cropping">
          <article>
            <h3>Video Smart Cropping</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-transformations">
          <article>
            <h3>Video Transformations</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712358821/Video-Transformation-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712358821/Video-Transformation-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/image-to-video">
          <article>
            <h3>Image to Video</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712512097/zoom-pan-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712512097/zoom-pan-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-generation">
          <article>
            <h3>Video Generation</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712769476/videoAPI_video-gen-thumb-fashion" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712769476/videoAPI_video-gen-thumb-fashion.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://demo.cloudinary.com/uw">
          <article>
            <h3>Upload Widget</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
    </div>;

  return (
    <div className="page-container adaptive-bitrate">

      <div className="hero">
      { tag }
      { title }
      { desc }
      </div>

    
      <PageTopSection
        assetsSelectType="video"
        assets={videoList}
        strings={pageStrings}
        controls={true}
      />
      <div className="bottom-section">
        <AdaptiveStreamingPlayer
          onSelectedResolutionChanged={handleSelectedResolutionChanged}
          onVideoWidthChanged={handleVideoWidthChanged}
          streamingProfile={streamingProfile}
          onQualityLevelsChange={handleQualityLevelsChange}
          publicId={curAsset}
        />

        <PageTopSection
          strings={pageStrings}
          controls={
            <VideoOptimizationControls
              values={values}
              controls={videoOptimizationControls}
              title={t(pageStrings.controlsTitle)}
              subtitle={t(pageStrings.controlsSubTitle)}
            />
          }
        />


        <div className="profile-bar">
          <div>{t(pageStrings.profileBarTitle)}</div>
          <div className="bar-container">
            {streamingProfilesList.map(profile => (
              <div
                key={profile.name}
                className={clsx('profile', {
                  active: activeResolution === profile.name,
                  selected: selectedResolution === profile.name
                })}
              >
                {profile.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      {links}
      {docs}

      {otherTitle}
      {other}
      
    </div>
  );
}

export default AdaptiveBitrate;
