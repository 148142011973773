import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SvgIconChildren() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 12">
        <path
          d="M10.0328 2.07995C8.94305 1.33084 7.77854 1.37238 6.74927 1.77163C7.93916 2.28765 8.8032 3.83848 8.8032 5.67225C8.8032 7.50556 7.93916 9.05685 6.74927 9.57241C7.77854 9.9712 8.94259 10.0127 10.0328 9.26363C10.8599 8.29621 11.3639 7.04446 11.3639 5.67179C11.3639 4.29912 10.8599 3.04737 10.0328 2.07995Z"
          fill="#777777"
        />
        <path
          d="M4.00216 8.79302C3.38967 8.07022 2.99319 7.00125 2.96642 5.80212C2.96642 5.79889 2.96642 5.54411 2.96642 5.54134C2.99319 4.34222 3.39013 3.27325 4.00216 2.55045C4.79696 1.51841 5.97809 0.864381 7.29676 0.864381C8.10817 0.864381 8.86744 1.11224 9.51639 1.54287C8.54158 0.670527 7.25568 0.138351 5.84562 0.133274C5.83916 0.133274 5.83223 0.132812 5.82531 0.132812C2.76657 0.132812 0.286621 2.61276 0.286621 5.6715C0.286621 8.64209 2.62487 11.0662 5.5613 11.2037C5.649 11.2079 5.73669 11.2102 5.82531 11.2102C7.24322 11.2102 8.5365 10.6771 9.51639 9.8006C8.86744 10.2312 8.10817 10.4791 7.29676 10.4791C5.97809 10.4791 4.79696 9.8246 4.00216 8.79302Z"
          fill="#777777"
        />
      </svg>
    </SvgIcon>
  );
}
