import React from 'react';
import { useTranslation } from 'react-i18next';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import './HistogramChart.scss';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const convertNumToTime = number => {
  var sign = number >= 0 ? 1 : -1;
  var time = '';
  number = number * sign;
  var hour = Math.floor(number);
  var decpart = number - hour;
  var min = 1 / 60;
  decpart = min * Math.round(decpart / min);
  var minute = Math.floor(decpart * 60) + '';
  if (minute.length < 2) {
    minute = '0' + minute;
  }
  sign = sign === 1 ? '' : '-';
  time = sign + hour + ':' + minute;
  return time;
};

function HistogramChart(props) {
  const { dataSet, range } = props;
  const { t } = useTranslation();

  const data = {
    labels: dataSet.map(label => label),
    datasets: [
      {
        id: 1,
        label: '',
        data: dataSet,
        borderColor: '#3549c5'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    scales: {
      x: { display: false },
      y: { display: false }
    },
    elements: {
      point: {
        radius: 0
      }
    }
  };

  const rangeStart = convertNumToTime(range[0]);
  const rangeEnd = convertNumToTime(range[1]);
  return (
    <div className="histogram-container">
      <div className="label">{t('automatedVideo.histogramLabel')}</div>
      <div className="chart-container">
        <Line className="chart" options={options} data={data} />
        <div className="range-ticks">
          <div className="tick">{rangeStart}</div>
          <div className="tick">{rangeEnd}</div>
        </div>
      </div>
    </div>
  );
}

export default HistogramChart;
