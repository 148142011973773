import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from '@mui/material/Slider';
import './RangeSlider.scss';
import { useStore } from '../../utils/store';

const getType = type => {
  switch (type) {
    case 'percentage':
      return '%';
    case 'seconds':
      return 's';
    case 'pixels':
      return 'px';
    case 'time':
      return 'x';
    default:
      return '';
  }
};

const getValue = (control, store) => {
  if (control.group) {
    return store[control.group][control.storeKey].value ?? 0;
  }
  return store[control.storeKey] ?? 0;
};

function RangeSlider(props) {
  const { control, onChange, isDisabled } = props;
  const { t } = useTranslation();
  const { store, updateStore } = useStore();
  const value = getValue(control, store);

  const handleChange = event => {
    if (control.group) {
      const newVal = {
        ...store[control.group][control.storeKey],
        value: event.target.value
      };
      updateStore({
        [control.group]: { ...store[control.group], [control.storeKey]: newVal }
      });
    } else {
      updateStore({ [control.storeKey]: event.target.value });
    }

    if (onChange) {
      onChange(control, event.target.value);
    }
  };

  const type = getType(control.valueType);
  return (
    <div className="range-slider-container">
      <div className="label">{t(control.label)}:</div>
      <div className="slider">
        <Slider
          step={control.step}
          disabled={isDisabled}
          value={value}
          onChange={handleChange}
          min={control.minVal}
          max={control.maxVal}
        />
      </div>
      <div className="value">{`${value}${type}`}</div>
    </div>
  );
}

export default RangeSlider;
