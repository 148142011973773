import React from 'react';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';

function PlayerEngagementControls(props) {
  const { title, controls } = props;
  return (
    <div className="controls-section">
      <p className="title">{title}</p>
      <div className="controls">
        {controls.map(control => {
          if (control.type === 'toggleSwitch') {
            return <ToggleSwitch key={control.key} control={control} />;
          }
          return null;
        })}
      </div>
    </div>
  );
}

export default PlayerEngagementControls;
