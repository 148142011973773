import { useEffect, useRef, useState } from 'react';
const checkAssetStatus = async url => {
  const result = await fetch(url, {
    method: 'HEAD'
  });
  return result.status === 200;
};
const usePoll = () => {
  const [url, setUrl1] = useState('');
  const [isReady, setIsReady] = useState(false);
  const intervalRef = useRef(null);
  const countRef = useRef(0);
  const setUrl = url => {
    setUrl1(url);
  };
  useEffect(() => {
    setIsReady(false);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      countRef.current = 0;
    }
    if (!url) {
      return;
    }

    const handle = async () => {
      if (await checkAssetStatus(url)) {
        setIsReady(true);
        return;
      }
      intervalRef.current = setInterval(async () => {
        if (countRef.current > 100) {
          clearInterval(intervalRef.current);
          setIsReady(false);
          return;
        }
        if (await checkAssetStatus(url)) {
          setIsReady(true);
          clearInterval(intervalRef.current);
        }
        countRef.current++;
      }, 1000 * 5);
    };

    handle();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [url]);
  return {
    isReady,
    setUrl
  };
};

export default usePoll;
