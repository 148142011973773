import React from 'react';
import { useTranslation } from 'react-i18next';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';
import SelectInput from 'components/selectInput/SelectInput';

function AiZoompanControls(props) {
  const { title, controls, autoZoomPan, createZoompanVideo } = props;
  const { t } = useTranslation();
  return (
    <div className="controls-section">
      <p className="title">{title}</p>
      <div className="controls">
        {controls.map(control => {
          if (control.type === 'toggleSwitch') {
            return <ToggleSwitch key={control.key} control={control} />;
          }
          if (control.type === 'select' && !autoZoomPan) {
            return <SelectInput key={control.key} control={control} />;
          }
          return null;
        })}
        <button className="btn primary apply-button" onClick={() => createZoompanVideo()}>
          {t('apply')}
        </button>
      </div>
    </div>
  );
}

export default AiZoompanControls;
