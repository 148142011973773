import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import VideoInteractivityControls from './VideoInteractivityControls';
import './VideoInteractivityPage.scss';
import Player from '../../components/player/Player';
import { useStore } from '../../utils/store';
import { getConfig } from './helper';

const videoInteractivityConfigKey = 'videoInteractivityConfig';
const videoList = [
  'rafting_autotag',
  'what_is_cloudinary_autotag',
  'imagecon_grigsby_intro_autotag',
  'marmots_autotag'
];

const videoInteractivityControls = [
  {
    key: 1,
    storeKey: 'hotSpot1',
    type: 'toggleSwitch',
    label: 'inVideoInteractivity.controls.hotSpot1',
    textInputPlaceHolder: 'inVideoInteractivity.controls.url',
    selectControl: {
      group: 'hotSpots.hotSpots1',
      storeKey: 'effect',
      selectOptions: [
        { name: 'Open URL', value: 'url' },
        { name: 'Zoom In', value: 'zoomin' }
      ]
    },
    textControl: {
      group: 'hotSpots.hotSpots1',
      storeKey: 'url'
    }
  },
  {
    key: 2,
    storeKey: 'hotSpot2',
    type: 'toggleSwitch',
    label: 'inVideoInteractivity.controls.hotSpot2',
    textInputPlaceHolder: 'inVideoInteractivity.controls.url',
    selectControl: {
      storeKey: 'hotSpot2Select',
      selectOptions: [
        { name: 'Open URL', value: 'url' },
        { name: 'Zoom In', value: 'zoomin' }
      ]
    },
    textControl: {
      storeKey: 'hotSpot2Text'
    }
  },
  {
    key: 3,
    storeKey: 'hotSpot3',
    type: 'toggleSwitch',
    label: 'inVideoInteractivity.controls.hotSpot3',
    textInputPlaceHolder: 'inVideoInteractivity.controls.url',
    selectControl: {
      storeKey: 'hotSpot3Select',
      selectOptions: [
        { name: 'Open URL', value: 'url' },
        { name: 'Zoom In', value: 'zoomin' }
      ]
    },
    textControl: {
      storeKey: 'hotSpot3Text'
    }
  }
];

const pageStrings = {
  title: 'inVideoInteractivity.title',
  subtitle: 'inVideoInteractivity.subtitle',
  detailed: 'inVideoInteractivity.detailed',
  assetSelectTitle: 'inVideoInteractivity.assetSelectTitle',
  controlsTitle: 'inVideoInteractivity.controlsTitle'
};

function VideoInteractivityPage() {
  const { t } = useTranslation();

  const { store, updateStore, resetStore } = useStore();
  const { selectedAsset, hotSpots, hotSpotContainerDimensions, uploadedVideos } = store;
  const sourcesRef = useRef({});
  const hotspotsRef = useRef(store.hotSpots);
  const curAssetRef = useRef(selectedAsset || videoList[0]);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  const [config, setConfig] = useState(false);
  const [hotSpotContInit, setHotSpotContInit] = useState(false);
  useEffect(() => {
    if (!curAssetRef.current) {
      return;
    }

    if (localStorage.getItem(videoInteractivityConfigKey) === null) {
      updateStore({ selectedAsset: curAssetRef.current });
    } else {
      try {
        const data = JSON.parse(localStorage.getItem(videoInteractivityConfigKey));

        curAssetRef.current = data.store.selectedAsset;
        updateStore({ selectedAsset: curAssetRef.current });
      } catch (e) {}
    }
  }, [curAssetRef.current]);

  useEffect(() => {
    if (!hotSpotContInit) {
      return;
    }
    if (localStorage.getItem(videoInteractivityConfigKey)) {
      try {
        const data = JSON.parse(localStorage.getItem(videoInteractivityConfigKey));
        updateStore({
          ...data.store
        });
        hotspotsRef.current = data.store.hotSpots;
        sourcesRef.current = data.sources;

        localStorage.removeItem(videoInteractivityConfigKey);
      } catch (e) {
        localStorage.removeItem(videoInteractivityConfigKey);
        window.location.reload();
      }
    }

    setConfig(
      getConfig(
        hotspotsRef.current,
        hotSpotContainerDimensions,
        curAssetRef.current,
        sourcesRef.current
      )
    );
    setPrintPlayer(true);
  }, [hotSpotContInit]);
  useEffect(() => {
    if (!hotSpotContainerDimensions.width && !hotSpotContainerDimensions.height) {
      return;
    }
    if (hotSpotContInit) {
      return;
    }
    setHotSpotContInit(true);
  }, [hotSpotContainerDimensions]);

  const [printPlayer, setPrintPlayer] = useState(false);

  const updateConfig = () => {
    const dataToSave = {
      store: {
        hotSpotContainerDimensions,
        hotSpots,
        selectedAsset,
        uploadedVideos
      },
      sources: sourcesRef.current,
      config: getConfig(
        hotspotsRef.current,
        hotSpotContainerDimensions,
        selectedAsset,
        sourcesRef.current
      )
    };

    localStorage.setItem(videoInteractivityConfigKey, JSON.stringify(dataToSave));
    window.location.reload();
  };
  if (!selectedAsset) {
    return <div></div>;
  }
  return (
    <div className="page-container video-interactivity-page">
      <PageTopSection
        assetsSelectType="video"
        assets={videoList}
        isAssetMultiPointSelect
        strings={pageStrings}
        controls={
          <VideoInteractivityControls
            updateConfig={updateConfig}
            controls={videoInteractivityControls}
            title={t(pageStrings.controlsTitle)}
          />
        }
      />
      <div className="player-container">
        {printPlayer && config && <Player publicId={curAssetRef.current} config={config}></Player>}
      </div>
    </div>
  );
}

export default VideoInteractivityPage;
