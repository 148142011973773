import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import AutomatedVideoControls from './AutomatedVideoControls';
import './AutomatedVideoPage.scss';
import { preview } from '@cloudinary/url-gen/actions/videoEdit';
import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';
import { useStore } from '../../utils/store';
import Video from '../../components/video/Video';
import { cld } from '../../utils/cld';
import usePoll from '../../hooks/usePoll';
import LoaderComponent from '../../components/loaderComponent/LoaderComponent';
import { scale } from "@cloudinary/url-gen/actions/resize";
import { format, quality } from "@cloudinary/url-gen/actions/delivery";
import { auto } from "@cloudinary/url-gen/qualifiers/format";
import {Cloudinary} from "@cloudinary/url-gen";
import {cldVideoDuration} from '../../utils/cld';

const videoList = [
  'samples/roadtrip_preview_xle9vy',
  'samples/mountain_adventure_preview_wa1vsg',
  'samples/airbnb_preview_ns4qqs',
  'samples/surfing_travel_preview_gk9nxe'
];

const automatedVideoControls = [
  {
    key: 1,
    type: 'slider',
    valueType: 'seconds',
    label: 'automatedVideo.controls.previewLength',
    minVal: 5,
    maxVal: 20,
    storeKey: 'ePreview'
  }
];

const pageStrings = {
  title: 'automatedVideo.title',
  subtitle: 'automatedVideo.subtitle',
  detailed: 'automatedVideo.detailed',
  assetSelectTitle: 'automatedVideo.assetSelectTitle',
  controlsTitle: 'automatedVideo.controlsTitle'
};

function AutomatedVideoPage() {
  const { store, updateStore, resetStore } = useStore();
  const { ePreview, selectedAsset } = store;
  const { t } = useTranslation();
  const [histogram, setHistogram] = useState([]);

  const [histogramRange, setHistogramRange] = useState([0, 0]);
  const curAsset = selectedAsset || videoList[0];
  const retryCount = useRef(0);
  const retryTimer = useRef(null);
  useEffect(() => {
    return () => {
      clearTimeout(retryTimer.current);
    };
  }, []);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  useEffect(() => {
    const fetchInfo = async () => {
      try {
        const res = await fetch(url);

        const data = await res.json();
        setHistogram(data.data);
        setHistogramRange(["0", "0.01"]);
      } catch (error) {
        if (retryCount.current > 100) {
          return;
        }
        retryTimer.current = setTimeout(() => {
          fetchInfo();
        }, 5000);
      }
    };
    const url = createCloudinaryLegacyURL(curAsset, {
      cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
      resource_type: 'video',
      effect: 'preview',
      flags: 'getinfo',
      secure: true
    });
    setHistogram([]);
    fetchInfo(url);
  }, [curAsset]);

  const currentVideo = cld.video(curAsset);
  currentVideo.resize(scale().width(600)).delivery(format(auto()));
  const myVideo = cld.video(curAsset);
  myVideo.videoEdit(preview().duration(`${ePreview}.0`)).resize(scale().width(600));
  useEffect(() => {
    updateStore({ selectedAsset: videoList[0] });
  }, []);
  const { isReady, setUrl } = usePoll();
  useEffect(() => {
    setUrl(myVideo.toURL());
  }, [myVideo.toURL()]);

  const originalUrl = cld.video(curAsset).toURL();
  const transformedURL = myVideo.toURL()+".mp4";

  const bgimg = <div class="bg-img"></div>;
  const tag = <div class="tag"><a href="https://cloudinary.com/demos?filter=videos">DEMOS</a> / Automatic Video Previews</div>;
  const title = <h1>Automatic Video Previews</h1>;
  const desc = <p>Generate video previews with engaging scenes using deep learning. </p>;
  const extra = <p class="extra">Results are generated in real time (can take up to few minutes).</p>

  const docs = <div class="docs-parent"><p class="orange">Original</p><p><a href={originalUrl} target="_blank">{originalUrl}</a></p><p class="orange">Transformed:</p><p><a href={transformedURL} target="_blank">{transformedURL}</a></p></div>;

  const links = <div className="links">Links: <a target="_blank" href="https://cloudinary.com/documentation/video_effects_and_enhancements#ai_based_video_preview">Docs</a> | <a target="_blank" href="https://cloudinary.com/blog/auto_generate_video_previews_with_great_results_every_time">Blog</a></div>;


  const otherTitle = <h2 class="other-title">Other Video Demos</h2>;
  const other = 
    <div class="row grid">      
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-optimization">
          <article>
            <h3>Dynamic Video Transcoding</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.mp4" width="100%" loop="" autoplay="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/adaptive-bitrate">
          <article>
            <h3>Adaptive Bitrate Streaming</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://studio.cloudinary.com/">
          <article>
            <h3>Video Player Studio</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-previews">
          <article>
            <h3>Automatic Video Previews</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-smart-cropping">
          <article>
            <h3>Video Smart Cropping</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-transformations">
          <article>
            <h3>Video Transformations</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712358821/Video-Transformation-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712358821/Video-Transformation-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/image-to-video">
          <article>
            <h3>Image to Video</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712512097/zoom-pan-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712512097/zoom-pan-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-generation">
          <article>
            <h3>Video Generation</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712769476/videoAPI_video-gen-thumb-fashion" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712769476/videoAPI_video-gen-thumb-fashion.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://demo.cloudinary.com/uw">
          <article>
            <h3>Upload Widget</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
    </div>;

  return (
    <div className="page-container automated-video-page">


      <div className="hero">
      { tag }
      { title }
      { desc }
      </div>


      <div className="bottom-section">

        <PageTopSection
          assetsSelectType="video"
          assets={videoList}
          strings={pageStrings}
          histogram={{ data: histogram, range: histogramRange }}
          controls={
            <AutomatedVideoControls
              controls={automatedVideoControls}
              title={t(pageStrings.controlsTitle)}
            />
          }
        />

        <div className="vid-parent">

          <div>
            <p>Original</p>
            <Video cldVid={currentVideo}  />
          </div>

          <div>
            <p>Preview</p>
            {isReady ? <Video cldVid={myVideo} /> : <LoaderComponent />}
          </div>
        </div>

        {links}

        { docs }
        
        </div>
        
        {otherTitle}
        {other}
      

    </div>
  );
}

export default AutomatedVideoPage;
