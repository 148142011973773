import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './HomePage.scss';
import aiDrivenCroppingImage from 'assets/home-page-images/ai-driven-cropping-image.png';
import aiZoompanImage from 'assets/home-page-images/ai-zoompan-image.png';
import automatedVideoImage from 'assets/home-page-images/automated-video-image.png';
import imagesToVideoImage from 'assets/home-page-images/images-to-video-image.png';
import inVideoInteractivityImage from 'assets/home-page-images/in-video-interactivity-image.png';
import optimizationsImage from 'assets/home-page-images/optimizations-image.png';
import playerEngagementImage from 'assets/home-page-images/player-engagement-image.png';
import playerFlexibiltyImage from 'assets/home-page-images/player-flexibility-image.png';
import smartIntegrationsImage from 'assets/home-page-images/smart-integrations-image.png';
import videoTransformationsImage from 'assets/home-page-images/video-transformations-image.png';
import { useStore } from '../../utils/store';

const columnsBigScreens = [
  {
    key: 1,
    colItems: [
      { url: '/smart-zoompan', image: aiZoompanImage },
      { url: '/player-engegament', image: playerEngagementImage },
      { url: 'https://studio.cloudinary.com', image: playerFlexibiltyImage, external: true },
      { url: '/video-optimization', image: optimizationsImage }
    ]
  },
  {
    key: 2,
    colItems: [
      { url: '/ai-cropping', image: aiDrivenCroppingImage },
      { url: '/images-to-video', image: imagesToVideoImage },
      { url: '/smart-integrations', image: smartIntegrationsImage }
    ]
  },
  {
    key: 3,
    colItems: [
      { url: '/video-interactivity', image: inVideoInteractivityImage },
      { url: '/automated-video', image: automatedVideoImage },
      { url: '/video-transformations', image: videoTransformationsImage }
    ]
  }
];

const columnsSmallScreen = [
  {
    key: 1,
    colItems: [
      { url: '/smart-zoompan', image: aiZoompanImage },
      { url: '/player-engegament', image: playerEngagementImage },
      { url: 'https://studio.cloudinary.com', image: playerFlexibiltyImage, external: true },
      { url: '/video-optimization', image: optimizationsImage },
      { url: '/video-interactivity', image: inVideoInteractivityImage },
      { url: '/video-transformations', image: videoTransformationsImage }
    ]
  },
  {
    key: 2,
    colItems: [
      { url: '/ai-cropping', image: aiDrivenCroppingImage },
      { url: '/images-to-video', image: imagesToVideoImage },
      { url: '/smart-integrations', image: smartIntegrationsImage },
      { url: '/automated-video', image: automatedVideoImage }
    ]
  }
];

function HomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { resetStore } = useStore();
  const [columns, seColumns] = useState(null);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);

  useEffect(() => {
    const { innerWidth: width } = window;
    if (columns === null) {
      if (width <= 700) {
        seColumns(columnsSmallScreen);
      } else {
        seColumns(columnsBigScreens);
      }
    }
  }, [columns]);

  return (
    <div className="page-container home-page">
      <h1 className="title">{t('homeTitle')}</h1>
      <h2 className="subtitle">{t('homeSubtitle')}</h2>
      <div className="pages-links">
        {columns?.map(column => (
          <div key={column.key} className="links-column">
            {column.colItems.map(item => (
              <div
                key={item.url}
                onClick={() => {
                  if (item.external) {
                    window.open(item.url, '_blank');
                    return;
                  }
                  navigate(item.url);
                }}
                className="page-nav-button"
              >
                <img src={item.image} alt="" className="page-nav-image" />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomePage;
