import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudinaryLogo from 'assets/svg-icons/CloudinaryLogo';
import MenuIcon from '@mui/icons-material/Menu';
import { useStore } from '../../utils/store';

export default function Footer(){
    return (
        <>
            <footer id="footer" className="site-footer" role="contentinfo">
              <div className="container-fluid">
                <div className="row footer-holder">
                  <div className="col-xs-12">
                    <div className="widgets">
                      <div className="col footer-logo">
                        <a href="/">
                          <svg
                            id="cld-footer-logo"
                            style={{ height: 30, width: 160, marginBottom: "-10px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 500 96.77"
                          >
                            <title>Cloudinary Logo</title>
                            <defs>
                              <style
                                dangerouslySetInnerHTML={{
                                  __html: ".cls-1-footer{fill:#ffffff;}"
                                }}
                              />
                            </defs>
                            <g id="Layer_2" data-name="Layer 2">
                              <g id="Layer_1-2" data-name="Layer 1">
                                <path
                                  className="cls-1-footer"
                                  d="M160.53,30.41a17.14,17.14,0,0,1,13.56,6.7.69.69,0,0,0,1,.11l5.71-4.55a.71.71,0,0,0,.11-1,26,26,0,0,0-20.61-10.13c-14.91,0-27,12.85-27,28.65s12.13,28.65,27,28.65A25.85,25.85,0,0,0,180.9,68.72a.69.69,0,0,0-.12-1l-5.7-4.5a.71.71,0,0,0-1,.11A17.26,17.26,0,0,1,160.53,70c-10.19,0-18.16-8.7-18.16-19.79S150.34,30.41,160.53,30.41Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M188.27,19.91h7.16a.71.71,0,0,1,.71.71V77.4a.7.7,0,0,1-.7.7h-7.16a.71.71,0,0,1-.71-.71V20.62A.7.7,0,0,1,188.27,19.91Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M220.54,39.55c-9.49,0-19.09,6.72-19.09,19.57,0,11.29,8.21,19.81,19.09,19.81s19.17-8.52,19.17-19.81S231.47,39.55,220.54,39.55Zm10.53,19.57c0,6.52-4.53,11.44-10.53,11.44S210.1,65.64,210.1,59.12s4.49-11.2,10.44-11.2S231.07,52.73,231.07,59.12Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M278.3,40.37h-7.16a.7.7,0,0,0-.71.7v19c0,7.42-5.12,10.05-9.51,10.05-3.88,0-7.79-2.93-7.79-9.48V41.07a.7.7,0,0,0-.71-.7h-7.16a.7.7,0,0,0-.7.7v20.5c0,11.25,5.09,17.44,14.34,17.44,3.36,0,8.8-1.93,10.84-6.19l.69.14V77.4a.71.71,0,0,0,.71.71h7.16a.71.71,0,0,0,.71-.71V41.07A.7.7,0,0,0,278.3,40.37Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M322.27,19.91H315.1a.7.7,0,0,0-.7.71V46l-.44-.7c-2.18-3.51-6.87-5.78-11.95-5.78-8.76,0-17.62,6.75-17.62,19.65,0,11.25,7.61,19.73,17.69,19.73,3.84,0,9.25-1.54,11.88-5.86l.44-.72V77.4a.7.7,0,0,0,.7.71h7.17a.7.7,0,0,0,.7-.71V20.62A.7.7,0,0,0,322.27,19.91Zm-8,39.21a11,11,0,0,1-10.75,11.36c-5.86,0-10.45-5-10.45-11.36s4.59-11.2,10.45-11.2A11,11,0,0,1,314.24,59.12Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M333,40.37h7.16a.7.7,0,0,1,.7.7V77.4a.7.7,0,0,1-.7.7H333a.71.71,0,0,1-.71-.71V41.07A.71.71,0,0,1,333,40.37Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M336.61,21.06a5.57,5.57,0,0,0-5.69,5.57,5.64,5.64,0,0,0,5.69,5.58,5.54,5.54,0,0,0,5.61-5.58A5.48,5.48,0,0,0,336.61,21.06Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M370.35,39.55c-3.14,0-8.72,1.69-10.85,6.19l-.69-.14V41.07a.7.7,0,0,0-.71-.7h-7.16a.7.7,0,0,0-.7.7V77.4a.7.7,0,0,0,.7.71h7.16a.71.71,0,0,0,.71-.71v-19c0-7.36,5.12-10,9.51-10,3.88,0,7.79,2.91,7.79,9.4V77.4a.71.71,0,0,0,.71.71H384a.71.71,0,0,0,.71-.71V56.91C384.69,45.72,379.59,39.55,370.35,39.55Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M427.48,40.37h-7.16a.7.7,0,0,0-.71.7v5l-.43-.7c-2.19-3.51-6.88-5.78-12-5.78-8.75,0-17.62,6.75-17.62,19.65,0,11.25,7.61,19.73,17.7,19.73,3.83,0,9.24-1.54,11.88-5.86l.43-.72V77.4a.71.71,0,0,0,.71.71h7.16a.7.7,0,0,0,.7-.71V41.07A.7.7,0,0,0,427.48,40.37Zm-8,18.75A11,11,0,0,1,408.7,70.48c-5.86,0-10.44-5-10.44-11.36s4.58-11.2,10.44-11.2A11,11,0,0,1,419.46,59.12Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M460.15,40.5a13.66,13.66,0,0,0-5.14-1c-4.76,0-8.22,2.85-10,8.25l-.64-.09V41.07a.7.7,0,0,0-.71-.7h-7.16a.7.7,0,0,0-.71.7V77.4a.71.71,0,0,0,.71.71h7.24a.7.7,0,0,0,.7-.71V65c0-14.8,5.91-17,9.44-17a11,11,0,0,1,4.33.9.72.72,0,0,0,.61,0,.7.7,0,0,0,.36-.48l1.42-7.11A.71.71,0,0,0,460.15,40.5Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M499.88,40.68a.69.69,0,0,0-.59-.31h-7.71a.72.72,0,0,0-.66.45L481.59,65l-9.42-24.18a.72.72,0,0,0-.66-.45h-7.86a.69.69,0,0,0-.58.31.7.7,0,0,0-.07.66l14,34.38-7.73,20.09a.71.71,0,0,0,.66,1h7.5a.69.69,0,0,0,.65-.45l21.86-55A.69.69,0,0,0,499.88,40.68Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M97.91,28.11A40.38,40.38,0,0,0,59.73,0,39.62,39.62,0,0,0,24.6,20.87a29.88,29.88,0,0,0-7.21,56.56l.75.34h.05v-8.5a22.29,22.29,0,0,1,9.29-41.16l2.1-.22L30.5,26A32.15,32.15,0,0,1,59.73,7.57a32.7,32.7,0,0,1,31.55,25L92,35.43l3,0a18.53,18.53,0,0,1,18.15,18.46c0,7.05-4.07,12.82-11,15.74v8.06l.5-.16c11.14-3.65,18.06-12.71,18.06-23.64A26.19,26.19,0,0,0,97.91,28.11Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M45.07,76.79l1.66,1.66a.33.33,0,0,1-.23.56H33.4a6,6,0,0,1-6-6V47.57a.33.33,0,0,0-.33-.33H24.27a.33.33,0,0,1-.24-.56L35.15,35.56a.33.33,0,0,1,.47,0L46.73,46.68a.33.33,0,0,1-.23.56H43.66a.34.34,0,0,0-.34.33v25A6,6,0,0,0,45.07,76.79Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M69.64,76.79l1.67,1.66a.33.33,0,0,1-.24.56H58a6,6,0,0,1-6-6V54a.34.34,0,0,0-.33-.34H48.84a.33.33,0,0,1-.23-.56L59.72,42a.33.33,0,0,1,.47,0L71.31,53.08a.33.33,0,0,1-.24.56H68.23a.34.34,0,0,0-.33.34V72.57A6,6,0,0,0,69.64,76.79Z"
                                />
                                <path
                                  className="cls-1-footer"
                                  d="M94.22,76.79l1.66,1.66a.33.33,0,0,1-.23.56H82.54a6,6,0,0,1-6-6V60.38a.33.33,0,0,0-.33-.33H73.41a.33.33,0,0,1-.23-.57L84.3,48.37a.32.32,0,0,1,.46,0L95.88,59.48a.33.33,0,0,1-.23.57H92.8a.33.33,0,0,0-.33.33V72.57A6,6,0,0,0,94.22,76.79Z"
                                />
                              </g>
                            </g>
                          </svg>
                        </a>
                      </div>
                      <div className="col">
                        <nav className="widget_text footer-nav">
                          <strong className="title">Products</strong>
                          <div className="textwidget custom-html-widget">
                            <ul>
                              <li>
                                <a
                                  href="/products/programmable_media"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Programmable Media'})"
                                >
                                  Programmable Media
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/products/digital_asset_management"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'DAM'})"
                                >
                                  DAM
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/demos"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Demos'})"
                                >
                                  Demos
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/pricing"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Pricing'})"
                                >
                                  Pricing
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://cloudinary.com/roadmap"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Roadmap'})"
                                >
                                  Roadmap
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/faq"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'FAQ'})"
                                >
                                  FAQ
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>{" "}
                      </div>
                      <div className="col">
                        <nav className="widget_text footer-nav">
                          <strong className="title">Solutions</strong>
                          <div className="textwidget custom-html-widget">
                            <ul>
                              <li>
                                <a
                                  href="/video_api"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Video API'})"
                                >
                                  Video API
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/solutions/ecommerce"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'E-commerce'})"
                                >
                                  E-commerce
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/solutions/retail"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Retail'})"
                                >
                                  Retail
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/solutions/industries/media_entertainment"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Media & Entertainment'})"
                                >
                                  Media &amp; Entertainment
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/solutions/industries/travel_hospitality"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Travel & Hospitality'})"
                                >
                                  Travel &amp; Hospitality
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/solutions/industries/cloudinary-for-nonprofits"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Non-Profits'})"
                                >
                                  Non-Profits
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/customers"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Our Customers'})"
                                >
                                  Our Customers
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/resources"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Resource Library'})"
                                >
                                  Resource Library
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>{" "}
                      </div>
                      <div className="col">
                        <nav className="widget_text footer-nav">
                          <strong className="title">Developers</strong>
                          <div className="textwidget custom-html-widget">
                            <ul>
                              <li>
                                <a
                                  href="/solutions/cloudinary_for_developers"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Getting Started'})"
                                >
                                  Getting Started
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/documentation"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Documentation'})"
                                >
                                  Documentation
                                </a>
                              </li>
                              <li>
                                <a href="https://community.cloudinary.com/">Community</a>
                              </li>
                              <li>
                                <a
                                  href="/documentation/cloudinary_sdks"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'SDKs'})"
                                >
                                  SDKs
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/addons"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Add-Ons'})"
                                >
                                  Add-Ons
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/podcasts"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Podcasts'})"
                                >
                                  Podcasts
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/cookbook"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Cookbook'})"
                                >
                                  Cookbook
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>{" "}
                      </div>
                      <div className="col">
                        <nav className="widget_text footer-nav">
                          <strong className="title">Company</strong>
                          <div className="textwidget custom-html-widget">
                            <ul>
                              <li>
                                <a
                                  href="/about"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'About Us'})"
                                >
                                  About Us
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/customers"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Customers'})"
                                >
                                  Customers
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/partners"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Partners'})"
                                >
                                  Partners
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/events"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Events'})"
                                >
                                  Events
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/careers"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Careers'})"
                                >
                                  Careers
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/newsroom"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Newsroom'})"
                                >
                                  Newsroom
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/blog/"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Blog'})"
                                >
                                  Blog
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/trust"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Trust'})"
                                >
                                  Trust
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>{" "}
                      </div>
                      <div className="col">
                        <nav className="widget_text footer-nav">
                          <strong className="title">Contact Us</strong>
                          <div className="textwidget custom-html-widget">
                            <ul>
                              <li>
                                <a
                                  href="https://support.cloudinary.com/hc/en-us"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Technical Support"
                                >
                                  Technical Support
                                </a>
                              </li>
                              <li>
                                <a
                                  href="/contact"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Contact Sales'})"
                                >
                                  Contact Sales
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://training.cloudinary.com/"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Education & Training'})"
                                >
                                  Education &amp; Training
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>
                        <nav className="widget_text footer-nav">
                          <div className="textwidget custom-html-widget">
                            <ul className="social-networks">
                              <li>
                                <a
                                  href="https://twitter.com/cloudinary"
                                  aria-label="Twitter page"
                                  className="twitter"
                                  data-popup="true"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'external navigation','eventLabel': 'Twitter'})"
                                >
                                  <svg
                                    alt="twitter icon"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="twitter"
                                    className="svg-inline--fa fa-twitter fa-w-16"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    width={15}
                                    height={16}
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                    />
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.linkedin.com/company/cloudinary/"
                                  className="linkedin"
                                  aria-label="Linkedin page"
                                  data-popup="true"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'external navigation','eventLabel': 'LinkedIn'})"
                                >
                                  <svg
                                    alt="linkedin icon"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="linkedin-in"
                                    className="svg-inline--fa fa-linkedin-in fa-w-14"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    width={14}
                                    height={16}
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                                    />
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.facebook.com/Cloudinary"
                                  className="facebook"
                                  aria-label="Facebook page"
                                  alt="facebook icon"
                                  data-popup="true"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'external navigation','eventLabel': 'Facebook'})"
                                >
                                  <svg
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="facebook-f"
                                    className="svg-inline--fa fa-facebook-f fa-w-10"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    width={9}
                                    height={16}
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                                    />
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.instagram.com/cloudinarylife"
                                  className="instagram"
                                  aria-label="Instagram page"
                                  data-popup="true"
                                  onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'external navigation','eventLabel': 'Instagram'})"
                                >
                                  <svg
                                    alt="instagram icon"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fab"
                                    data-icon="instagram"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512"
                                    width={14}
                                    height={16}
                                    className="svg-inline--fa fa-instagram fa-w-14 fa-2x"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                      className=""
                                    />
                                  </svg>
                                </a>
                              </li>
                              <li>
                                <a
                                  href="https://www.youtube.com/@Cloudinary"
                                  className="instagram"
                                  aria-label="Youtube page"
                                  data-popup="true"
                                >
                                  <svg
                                    version="1.1"
                                    className="svg-inline--fa fa-instagram fa-w-14 fa-2x"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 159 110"
                                    style={{ width: 15 }}
                                  >
                                    <path
                                      fill="currentColor"
                                      className="st0"
                                      d="M154,17.5c-1.8-6.7-7.1-12-13.8-13.8c-9-3.5-96.6-5.2-122,0.1c-6.7,1.8-12,7.1-13.8,13.8
                C0.3,35.5,0,74.2,4.5,92.5c1.8,6.7,7.1,12,13.8,13.8c17.9,4.1,103,4.7,122,0c6.7-1.8,12-7.1,13.8-13.8C158.5,73,158.8,36.7,154,17.5
                z M64.2,78.4V31.6L105,55L64.2,78.4z"
                                    />
                                  </svg>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </nav>{" "}
                      </div>
                      <div className="col">
                        <div className="widget_text footer-nav">
                          <div className="textwidget custom-html-widget">
                            <div className="footer-seals">
                              <ul>
                                <li>
                                  <img
                                    src="https://cloudinary-res.cloudinary.com/image/upload/b_rgb:0C163B,c_scale,f_auto,q_auto,w_76,dpr_auto/v1563215041/website/seals/L_ISO_27001_2013_E.png"
                                    className=" ls-is-cached lazyloaded"
                                    width={76}
                                    height={76}
                                    alt="Institute of Quality & Control"
                                    loading="lazy"
                                  />
                                </li>
                                <li>
                                  <img
                                    src="https://cloudinary-res.cloudinary.com/image/upload/b_rgb:0C163B,c_scale,f_auto,q_auto,w_76,dpr_auto/v1563215041/website/seals/GDPR.png"
                                    className=" ls-is-cached lazyloaded"
                                    width={76}
                                    height={76}
                                    alt="GDPR"
                                    loading="lazy"
                                  />
                                </li>
                                <li>
                                  <img
                                    src="https://cloudinary-res.cloudinary.com/image/upload/b_rgb:0C163B,c_scale,f_auto,q_auto,w_76,dpr_auto/v1563215041/website/seals/21972-312_SOC_NonCPA.png"
                                    className=" ls-is-cached lazyloaded"
                                    width={76}
                                    height={76}
                                    alt="SOC"
                                    loading="lazy"
                                  />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-bot">
                <div className="container-fluid">
                  <div className="row footer-frame">
                    <div className="col-xs-12 col-sm-6">
                      <div className="widget_text footer-nav">
                        <div className="textwidget custom-html-widget">
                          <div className="footer-seals">
                            <ul>
                              <li>
                                <img
                                  src="https://cloudinary-res.cloudinary.com/image/upload/dpr_auto,c_scale,f_auto,q_auto,w_95/v1590418918/website/seals/Forbes_US_BESU2020_Siegel_Basic.eps"
                                  className=" ls-is-cached lazyloaded"
                                  width={95}
                                  height={50}
                                  alt="Forbes Best Startup Employers 2020"
                                  loading="lazy"
                                />
                              </li>
                              <li>
                                <img
                                  src="https://res.cloudinary.com/cloudinary-marketing/image/upload/dpr_auto,c_scale,f_auto,q_auto,w_60/v1639749034/MarTech_Breakthrough_Awards_2021_Cloudinary_1.png"
                                  className=" ls-is-cached lazyloaded"
                                  width={60}
                                  height={62}
                                  alt="MarTech Breakthrough Awards"
                                  loading="lazy"
                                />
                              </li>
                              <li>
                                <img
                                  src="https://res.cloudinary.com/cloudinary-marketing/image/upload/dpr_auto,c_scale,f_auto,q_auto,w_99/creative_staging/cloudinary_internal/Website/primary_navigation/2021-BPTW_Logo.png"
                                  className=" ls-is-cached lazyloaded"
                                  width={99}
                                  height={77}
                                  alt="Best Places to Work SVBJ 2021"
                                  loading="lazy"
                                />
                              </li>
                              <li>
                                <img
                                  src="https://res.cloudinary.com/cloudinary-marketing/image/upload/dpr_auto,c_scale,f_auto,q_auto,w_63/v1601359190/creative_staging/cloudinary_internal/Website/primary_navigation/Cloud1002020.eps"
                                  className=" ls-is-cached lazyloaded"
                                  width={63}
                                  height={60}
                                  alt="2020 The World's Best Cloud Companies"
                                  loading="lazy"
                                />
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <ul className="footer-links">
                        <li>
                          <a
                            href="/tos"
                            onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Terms of Use'})"
                          >
                            Terms of Use
                          </a>
                        </li>
                        <li>
                          <a
                            href="/privacy"
                            onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'Privacy Policy'})"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        <li>
                          <a
                            href="/dmca"
                            onclick="dataLayer.push({'event': 'event to GA','eventCategory': 'website footer menu','eventAction': 'navigate','eventLabel': 'DMCA Notice'})"
                          >
                            DMCA Notice
                          </a>
                        </li>
                      </ul>
                      <div className="copyright">
                        <p>© 2024 Cloudinary. All rights reserved.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
        </>
    )
}