import { useEffect, useRef, useState } from 'react';

const AdaptiveStreamingPlayer = props => {
  const {
    onVideoWidthChanged,
    publicId,
    streamingProfile,
    onSelectedResolutionChanged,
    onQualityLevelsChange
  } = props;
  const playerRef = useRef(null);
  const vpRef = useRef(null);
  const [firstRun, setFirstRun] = useState(true);

  useEffect(() => {
    if (playerRef.current) {
      vpRef.current = window.cloudinary.videoPlayer(playerRef.current, {
        cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
      });
    }
  }, [playerRef]);
  const vPlayer = vpRef.current;
  useEffect(() => {
    if (!vPlayer || !publicId) {
      return;
    }
    vPlayer.source(publicId, {
      sourceTypes: ['hls'],
      transformation: { streaming_profile: streamingProfile }
    });
    setFirstRun(false);
  }, [vPlayer, publicId]);

  useEffect(() => {
    if (!vPlayer) {
      return;
    }
    if (firstRun) {
      return;
    }
    vPlayer
      .source(publicId, {
        sourceTypes: ['hls'],
        transformation: { streaming_profile: streamingProfile }
      })
      .play();
  }, [vPlayer, streamingProfile]);

  const handleQualityChange = qualityLevels => {
    const index = qualityLevels.selectedIndex;
    const width = qualityLevels[index].width;
    onSelectedResolutionChanged(width);
  };

  const handleResize = () => {
    if (!vPlayer) {
      return;
    }
    const width = vPlayer.videojs.videoWidth();
    onVideoWidthChanged(width);
  };

  useEffect(() => {
    if (!vPlayer) {
      return;
    }
    const qualityLevels = vPlayer.videojs.qualityLevels();

    qualityLevels.on('change', () => {
      onQualityLevelsChange(qualityLevels);
      handleQualityChange(qualityLevels);
    });
  }, [vPlayer]);

  return (
    <>
      <video
        onResize={handleResize}
        id="cld-player"
        ref={playerRef}
        className="cld-video-player cld-fluid"
        controls
      />
    </>
  );
};

export default AdaptiveStreamingPlayer;
