import React, { useEffect, useRef, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import SmartIntegrationsControls from './SmartIntegrationsControls';
import './SmartIntegrationsPage.scss';
import Player from '../../components/player/Player';
import { useStore } from '../../utils/store';

const videoList = [
  'samples/mountain_integrations_ip7sui',
  'samples/woman_dog_integrations_cw4idp',
  'samples/walking_integrations_zf1w8r',
  'samples/workout_punsh4'
];

const POLLING_INTERVAL = 1000 * 10;
const POLLING_MAX_COUNT = 60;
const smartIntegrationsControls = [
  {
    key: 1,
    name: 'tags',
    label: 'smartIntegrations.controls.tags'
  },

  {
    key: 2,
    name: 'transcript',
    label: 'smartIntegrations.controls.transcript'
  }
];

const pageStrings = {
  title: 'smartIntegrations.title',
  subtitle: 'smartIntegrations.subtitle',
  detailed: 'smartIntegrations.detailed',
  assetSelectTitle: 'smartIntegrations.assetSelectTitle',
  controlsTitle: 'smartIntegrations.controlsTitle'
};

const convertSecondsToTime = seconds => {
  return new Date(seconds * 1000).toISOString().slice(11, 19);
};

function SmartIntegrationsPage() {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [transcript, setTranscript] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const countRef = useRef(0);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isLoadingTranscript, setIsLoadingTranscript] = useState(false);
  const dataLoadedRef = useRef(false);

  const { store, updateStore, resetStore } = useStore();
  const { selectedAsset } = store;
  const curAsset = selectedAsset || videoList[0];
  const intervalRef = useRef(null);
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  useEffect(() => {
    updateStore({ selectedAsset: videoList[0] });
  }, [updateStore]);

  useEffect(() => {
    const fetchTranscript = async url => {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      });
      try {
        const data = await response.json();
        const parsedData = [];

        for (const section of data) {
          for (const word of section.words) {
            const text = `${convertSecondsToTime(word.start_time)}   ${word.word}`;
            parsedData.push({
              word: text,
              key: uuidv4()
            });
          }
        }
        setTranscript(parsedData);
      } catch (error) {}
    };
    const processTags = tagsData => {
      const tags = new Map();
      tagsData.forEach(tag => {
        if (!tags.has(tag.tag)) {
          tags.set(tag.tag, {
            count: 0,
            tag: tag.tag,
            appearsIn: [],
            key: tag.tag
          });
        }
        const meta = tags.get(tag.tag);
        meta.count++;
        meta.appearsIn.push({
          startTimeOffset: tag.start_time_offset,
          endTimeOffset: tag.end_time_offset
        });
      });
      return [...tags.values()];
    };
    const fetchData = async () => {
      try {
        const url = `${process.env.REACT_APP_TAGS_URL}/${curAsset}`;
        const response = await fetch(url);
        const data = await response.json();
        const tagsStatus = data?.tags?.status;
        const transcriptStatus = data?.transcript?.status;

        if (tagsStatus !== 'pending') {
          setIsLoadingTags(false);
        }
        if (transcriptStatus !== 'pending') {
          setIsLoadingTranscript(false);
        }
        if (data?.tags?.status === 'complete' && data?.tags?.data?.raw_tags_info) {
          setTags(processTags(data.tags.data.raw_tags_info));
        }
        if (data?.transcript?.status === 'complete' && data?.transcript?.data) {
          await fetchTranscript(data.transcript.data);
        }
        const needPolling = tagsStatus === 'pending' || transcriptStatus === 'pending';
        if (!needPolling) {
          setDataLoaded(true);
          dataLoadedRef.current = true;
        }
      } catch (error) {}
    };
    intervalRef.current = null;
    countRef.current = 0;
    setTranscript([]);
    setTags([]);
    setIsLoadingTags(true);
    setIsLoadingTranscript(true);
    fetchData();
    intervalRef.current = setInterval(() => {
      if (dataLoadedRef.current || countRef.current > POLLING_MAX_COUNT) {
        intervalRef.current = null;
        setDataLoaded(true);
        dataLoadedRef.current = true;
        return;
      }
      countRef.current++;
      fetchData();
    }, POLLING_INTERVAL);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [curAsset]);

  useEffect(() => {
    if (!dataLoaded) {
      return;
    }
    setDataLoaded(false);
    dataLoadedRef.current = false;
    if (!transcript.length) {
      setTranscript([{ word: 'No transcript available', key: uuidv4() }]);
    }
  }, [dataLoaded]);

  useEffect(() => {}, [curAsset]);
  const data = {
    transcript,
    tags
  };

  const bgimg = <div class="bg-img"></div>;
  const tag = <div class="tag">DEMOS</div>;
  const title = <h1>AI-driven Tagging & Transcripts</h1>;
  const desc = <p>Boost search and management of video assets with fast, accurate AI-based tagging and transcripts.</p>;
  const extra = <p class="extra">Results are generated in real time (can take up to few minutes).</p>
  const docs = <div class="docs-parent"><p class="orange">Original</p><p>https://res.cloudinary.com/demo/video/upload/rafting</p><p class="orange">Transformed</p><p>https://res.cloudinary.com/demo/video/upload/e_preview:duration_5/rafting.webm</p></div>
  const links = <div className="links">Links: <a target="_blank" href="https://cloudinary.com/documentation/auto_tagging_tutorial#overview">Docs</a> | <a target="_blank" href="https://cloudinary.com/guides/video-effects/automatically-generate-transcripts">Blog</a> </div>;

  return (
    <div className="page-container smart-integrations-page">


      <div className="hero">
      { tag }
      { title }
      { desc }
      </div>


      <PageTopSection
        assetsSelectType="video"
        assets={videoList}
        strings={pageStrings}
        uploadPreset={process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET}
      />
      <p className="controls-title">{t(pageStrings.controlsTitle)}</p>
      <div className="bottom-section">
        <SmartIntegrationsControls
          data={data}
          isLoadingTags={isLoadingTags}
          isLoadingTranscript={isLoadingTranscript}
          controls={smartIntegrationsControls}
          title={t(pageStrings.controlsTitle)}
        />
        <div className="player-container">
          <Player publicId={curAsset} config={{}}></Player>
        </div>
      </div>

      {links}

    </div>
  );
}

export default SmartIntegrationsPage;
