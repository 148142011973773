import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTopSection from 'components/pageTopSection/PageTopSection';
import ImagesToVideoControls from './ImagesToVideoControls';
import './ImagesToVideoPage.scss';
import { useStore } from '../../utils/store';
import { cld, cldCreateVideo } from '../../utils/cld';
import { fill } from '@cloudinary/url-gen/actions/resize';
import Video from '../../components/video/Video';
import LoaderComponent from 'components/loaderComponent/LoaderComponent';
import usePoll from '../../hooks/usePoll';

const photoList = [
  'samples/fashion_4_generation_qachzv',
  'samples/fashion_3_generation_jqubns',
  'samples/fashion_2_generation_dfqnis',
  'samples/fashion_generation_xaj0sc'
];

const imagesToVideoControls = [
  {
    key: 1,
    storeKey: 'effect',
    type: 'select',
    name: 'effect',
    selectOptions: [
      { name: 'circlecrop', value: 'circlecrop' },
      { name: 'morph', value: 'morph' },
      { name: 'pinwheel', value: 'pinwheel' }
    ]
  },
  {
    key: 2,
    storeKey: 'effectDuration',
    type: 'slider',
    name: 'effectDuration',
    valueType: 'seconds',
    label: 'imagesToVideo.controls.effectDuration',
    minVal: 1,
    maxVal: 5
  },
  {
    key: 3,
    storeKey: 'slideDuration',
    type: 'slider',
    name: 'slideDuration',
    valueType: 'seconds',
    label: 'imagesToVideo.controls.slideDuration',
    minVal: 1,
    maxVal: 5
  }
];

const pageStrings = {
  title: 'imagesToVideo.title',
  subtitle: 'imagesToVideo.subtitle',
  assetSelectTitle: 'imagesToVideo.assetSelectTitle',
  controlsTitle: 'imagesToVideo.controlsTitle'
};

const createManifest = (images, slideDuration, effectDuration, transition) => {
  const slideEndEffectDuration = slideDuration + effectDuration;
  const duration = images.length * slideEndEffectDuration;
  return {
    type: 'video',
    width: 500,
    height: 500,
    duration,
    fps: 30,
    tracks: [
      {
        width: 500,
        height: 500,
        x: 0,
        y: 0,
        clipDefaults: {
          clipDuration: slideDuration * 1000,
          transitionDuration: effectDuration * 1000,
          transition
        },
        clips: images.map(image => ({
          media: [image, 'image', 'upload'],
          type: 'image',
          transformation: 'c_fill'
        }))
      }
    ]
  };
};

function ImagesToVideoPage() {
  const { t } = useTranslation();

  const { store, updateStore, resetStore } = useStore();
  const { selectedAssets, slideDuration, effectDuration, effect, imagesToVideoId } = store;

  const [myVideo, setMyVideo] = useState(null);

  useEffect(() => {
    if (!imagesToVideoId) {
      setMyVideo(null);
    }
    setMyVideo(cld.video(imagesToVideoId).resize(fill().height(500)));
  }, [imagesToVideoId]);

  const { isReady, setUrl } = usePoll();
  useEffect(() => {
    return () => {
      resetStore();
    };
  }, []);
  const cldPhotoList = photoList.map(photo => {
    return cld.image(photo);
  });

  useEffect(() => {
    if (!myVideo) {
      return;
    }
    setUrl(myVideo.toURL());
  }, [myVideo]);

  const createVideo = async () => {
    const manifest = createManifest(selectedAssets, slideDuration, effectDuration, effect);
    const now = Date.now();
    const publicId = `video-${now}`;
    updateStore({ imagesToVideoId: publicId });
    const result = await cldCreateVideo(publicId, manifest);
    if (result.status !== 200) {
    }
  };

  const bgimg = <div class="bg-img"></div>;
  const tag = <div class="tag"><a href="https://cloudinary.com/demos?filter=videos">DEMOS</a> / Video Generation</div>;
  const title = <h1>Video Generation</h1>;
  const desc = <p>Eliminate expensive reshoots by generating videos at scale using existing assets and configurable templates. </p>;
  const extra = <p class="extra">Results are generated in real time (can take up to few minutes).</p>
  const docs = <div class="docs-parent">
  <p class="orange">Original</p>
  <p>https://res.cloudinary.com/demo/video/upload/rafting</p>
  <p class="orange">Transformed</p>
  <p>https://res.cloudinary.com/demo/video/upload/e_preview:duration_5/rafting.webm</p>
  </div>
const links = <div className="links">Links: <a target="_blank" href="https://cloudinary.com/documentation/video_generation">Docs</a></div>;


  const otherTitle = <h2 class="other-title">Other Video Demos</h2>;
  const other = 
    <div class="row grid">      
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-optimization">
          <article>
            <h3>Dynamic Video Transcoding</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926232/Demos_09_Video_Transcoding_Scaled.mp4" width="100%" loop="" autoplay="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/adaptive-bitrate">
          <article>
            <h3>Adaptive Bitrate Streaming</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322830/Demos_08_Content-Aware_Video_Compression_updated.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://studio.cloudinary.com/">
          <article>
            <h3>Video Player Studio</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1704322874/Demos_12_Video_Player_Studio_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-previews">
          <article>
            <h3>Automatic Video Previews</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926233/Demos_16_Video_Preview__Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-smart-cropping">
          <article>
            <h3>Video Smart Cropping</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_0/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926220/Demos_06_Content-Aware_Video_Cropping_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-transformations">
          <article>
            <h3>Video Transformations</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712358821/Video-Transformation-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712358821/Video-Transformation-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/image-to-video">
          <article>
            <h3>Image to Video</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712512097/zoom-pan-thumb" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712512097/zoom-pan-thumb.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://videoapi.cloudinary.com/video-demo/video-generation">
          <article>
            <h3>Video Generation</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/so_0/v1712769476/videoAPI_video-gen-thumb-fashion" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/w_600/f_auto,q_auto/v1712769476/videoAPI_video-gen-thumb-fashion.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
      <div class="col-sm-12 col-md-6 col-xl-4 mb-4 demoListing">
        <a href="https://demo.cloudinary.com/uw">
          <article>
            <h3>Upload Widget</h3>
            <video poster="https://cloudinary-marketing-res.cloudinary.com/video/upload/so_1/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.jpg" src="https://cloudinary-marketing-res.cloudinary.com/video/upload/f_auto,q_auto/v1702926234/Demos_15_UPLOAD_Widget_Scaled.mp4" width="100%" loop="" class="listVideo"></video>
          </article>
        </a>
      </div>
    </div>;



  document.addEventListener('DOMContentLoaded', function() {
    var container = document.querySelector('.asset-select-container');

    // Create a new paragraph element
    var paragraph = document.createElement('p');

    // Set the text content of the paragraph
    paragraph.textContent = "here is some content";

    // Append the paragraph to the container
    container.appendChild(paragraph);
  });

  return (
    <div className="page-container image-to-video-page">

      <div className="hero">
      { tag }
      { title }
      { desc }
      </div>

      <PageTopSection
        assetsSelectType="multiPhoto"
        assets={cldPhotoList}
        strings={pageStrings}
        controls={
          <ImagesToVideoControls
            selectedAssets={selectedAssets}
            createVideo={createVideo}
            controls={imagesToVideoControls}
            title={t(pageStrings.controlsTitle)}
          />
        }
      />
      <div className="bottom-section">
        {imagesToVideoId && !isReady && <LoaderComponent />}
        {isReady && <Video cldVid={myVideo}></Video>}
      </div>

      {links}

      {otherTitle}
      {other}
    </div>
  );
}

export default ImagesToVideoPage;
