import { createContext, useReducer, useContext } from 'react';

export const initialStore = {
  ePreview: 10,
  hideUi: true,
  autoZoomPan: true,
  selectedAssets: [],
  selectedAsset: '',
  zoomType: 'in',
  zoomFrom: {
    xPerc: 0,
    yPerc: 0
  },
  zoomFromPointSelect: {
    x: 100,
    y: 100
  },
  hotSpotContainerDimensions: {
    width: 0,
    height: 0
  },
  hotSpots: {
    hotSpot1: {
      url: '',
      source: '',
      enabled: true,
      effect: 'zoomin',
      x: 40,
      y: 40
    },
    hotSpot2: {
      url: '',
      source: '',
      enabled: false,
      effect: 'zoomin',
      x: 80,
      y: 80
    },
    hotSpot3: {
      url: '',
      source: '',
      enabled: false,
      effect: 'zoomin',
      x: 120,
      y: 120
    }
  },
  slideDuration: 3,
  effectDuration: 3,
  effect: 'circlecrop',
  imagesToVideoId: '',
  videoTransformations: {
    vignette: {
      enabled: false,
      value: 0
    },
    roundCorners: {
      enabled: false,
      value: 0
    },
    saturation: {
      enabled: false,
      value: 0
    },
    dimensions: {
      enabled: false,
      value: {
        width: 400,
        height: 400
      }
    },
    overlay: {
      enabled: false,
      value: 0
    },
    rotate: {
      enabled: false,
      value: null
    },
    preroll: {
      enabled: false
    },
    playbackSpeed: {
      enabled: false,
      value: 1
    },
    blur: {
      enabled: false,
      value: 0
    }
  },
  alwaysOnPlaylist: true,
  endScreenPlaylist: true,
  streamingProfile: 'sd',
  uploadedVideos: []
};

export const Store = createContext(initialStore);

export const useStore = () => useContext(Store);

export function StoreProvider(props) {
  const [store, updateStore] = useReducer(
    (state, payload) => ({ ...state, ...payload }),
    initialStore
  );
  const resetStore = () => updateStore({ ...initialStore });
  return (
    <Store.Provider value={{ store, updateStore, resetStore }}>{props.children}</Store.Provider>
  );
}
