import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import VideoSelect from 'components/videoSelect/VideoSelect';
import PhotoSelect from 'components/photoSelect/PhotoSelect';
import MultiPhotoSelect from 'components/multiPhotoSelect/MultiPhotoSelect';
import ArrowBack from '@mui/icons-material/ArrowBackIos';
import './PageTopSection.scss';

const renderSelect = (
  type,
  assets,
  strings,
  histogram,
  isAssetPointSelect,
  isAssetMultiPointSelect,
  isVideo,
  uploadPreset
) => {
  if (type === 'video') {
    return (
      <VideoSelect
        assets={assets}
        strings={strings}
        histogram={histogram}
        isAssetMultiPointSelect={isAssetMultiPointSelect}
        uploadPreset={uploadPreset}
      />
    );
  }
  if (type === 'photo') {
    return (
      <PhotoSelect assets={assets} strings={strings} isAssetPointSelect={isAssetPointSelect} />
    );
  }
  if (type === 'multiPhoto') {
    return <MultiPhotoSelect assets={assets} strings={strings} isVideo={isVideo} />;
  }
};

function PageTopSection(props) {
  const {
    isVideo,
    assetsSelectType,
    assets,
    strings,
    controls,
    histogram,
    isAssetPointSelect,
    isAssetMultiPointSelect,
    uploadPreset
  } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="top-section">
      <div className="page-info">
        <button className="btn back-button" onClick={() => navigate('/')}>
          <ArrowBack className="btn-icon" style={{ height: '16px', width: '16px' }} />
          {t('backToAllDemos')}
        </button>
        <h1 className="title">{t(strings.title)}</h1>
        <h2 className="subtitle">{t(strings.subtitle)}</h2>
        {/* <p className="detailed">
          <Trans i18nKey={strings.detailed} />
        </p> */}
      </div>
      <div className="asset-select-container">
        {renderSelect(
          assetsSelectType,
          assets,
          strings,
          histogram,
          isAssetPointSelect,
          isAssetMultiPointSelect,
          isVideo,
          uploadPreset
        )}
      </div>
      {controls}
    </div>
  );
}

export default PageTopSection;
