import React from 'react';
import ToggleSwitch from 'components/toggleSwitch/ToggleSwitch';

function AiCroppingControls(props) {
  const { title, controls } = props;
  return (
    <div className="controls-section">
      <p className="title">{title}</p>
      <div className="controls">
        {controls.map(control => (
          <ToggleSwitch key={control.key} control={control} />
        ))}
      </div>
    </div>
  );
}

export default AiCroppingControls;
