import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SvgIconChildren() {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
        <circle cx="10" cy="10" r="10" fill="black" />
        <path
          d="M15 5V13.125H13.125V15H5V6.875H6.875V5H15ZM14.375 12.5V5.625H7.5V6.875H8.75V7.5H5.625V14.375H12.5V11.25H13.125V12.5H14.375ZM9.59473 10.8447L9.15527 10.4053L12.0557 7.5H10V6.875H13.125V10H12.5V7.94434L9.59473 10.8447Z"
          fill="#fff"
        />
      </svg>
    </SvgIcon>
  );
}
