import { AdvancedVideo } from '@cloudinary/react';
import React, { useRef } from 'react';
const Video = props => {
  const { cldVid } = props;
  const ref = useRef(null);

  return <AdvancedVideo ref={ref} style={{ width: '100%' }} controls cldVid={cldVid} />;
};

export default React.memo(Video);
