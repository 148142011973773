import React, { useEffect, useRef } from 'react';
import usePoll from '../../hooks/usePoll';
import Spinner from 'components/loaderComponent/Spinner';

const CropVideo = props => {
  const { cldVid, play, setPlay, isAllReady, onReady } = props;

  const ref = useRef(null);
  const source = cldVid.toURL();
  const { isReady, setUrl } = usePoll();

  useEffect(() => {
    if (!isReady) {
      return;
    }
    onReady();
  }, [isReady]);
  useEffect(() => {
    setUrl(source);
  }, [source]);

  const sourceExt = source.split('.') > 0 ? source.split('.').pop() : '';
  const poster = sourceExt ? source.replace(sourceExt, 'jpg') : '';
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (play) {
      ref.current.play();
    }
    if (!play) {
      ref.current.pause();
    }
  }, [play, ref.current]);

  if (!isReady) {
    return (
      <div className="spinner-container">
        <Spinner size={40} negiveColor />
      </div>
    );
  }
  return (
    <div className="inner-container">
      <video
        onPlay={() => {
          setPlay(true);
        }}
        onPause={() => {
          setPlay(false);
        }}
        loop
        ref={ref}
        style={{ width: '100%' }}
        controls={isAllReady}
        poster={poster}
      >
        <source src={source} type="video/mp4" />
      </video>
    </div>
  );
};

export default React.memo(CropVideo);
